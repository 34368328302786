<template>
  <v-row justify="center" v-if="shower === true">
    <v-dialog
      v-model="shower"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      @keydown.esc="closedialog(), $emit('openDrag')"
    >
      <!-- onkeydown="disablef12(event)" -->
      <v-card id="dialogpreview" class="ma-0 pa-0">
        <v-toolbar
          :color="color.theme"
          :dark="color.darkTheme"
          :text-color="color.chipText"
          class="pa-0 ma-0"
          oncontextmenu="return false"
        >
          <v-btn
            icon
            dark
            @click="
              closedialog(),
                $emit('openDrag'),
                (test = true),
                (disablerightclick = false),
                (disableleftclick = false),
                (donotpreview = false),
                (isOpenShow_watermark = false),
                fn_create_destroy_event(false)
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="filedata.url_file !== undefined">{{
            filedata.document_id
          }}</v-toolbar-title>
          <v-toolbar-title v-else>{{
            filedata.file_name || filedata.filename
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :disabled="!checkfiledata || filedata.status_expired === '0'"
              v-if="filedata.file_type ? filedata.file_type === 'pdf' || (['jpeg', 'jpg', 'png', 'gif', 'tiff'].includes(filedata.file_type.toLowerCase())) : filedata.data_type === 'pdf' || (['jpeg', 'jpg', 'png', 'gif', 'tiff'].includes(filedata.data_type.toLowerCase()))"
              icon
              @click="fn_printPDF_single()"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn
              v-if="!officefilescompatible.includes(filedata_1.data_type)"
              :disabled="!checkfiledata || disableDataStorageOverload || filedata.status_expired === '0'"
              icon
              dark
              @click="downloadfile(filedata)"
              ><v-icon>cloud_download</v-icon></v-btn
            >
            <!-- <div v-else  class="mt-2">
            <v-btn
              v-if="!(filedata.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(filedata.file_type.toLowerCase()) || 
                filedata.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(filedata.data_type.toLowerCase()))"
              :disabled="!checkfiledata || disableDataStorageOverload"
              icon
              dark
              @click="downloadfile(filedata)"
              >
              <v-icon>cloud_download</v-icon></v-btn
            >
            </div> -->
          </v-toolbar-items>
        </v-toolbar>
        <!-- แบบเก่า -->
        <!-- <v-card-text
          style="height: 95vh; width: 100vw; overflow-y:auto; overflow-x:hidden;"
          class="pa-0"
        > -->
        <!-- แบบใหม่ -->
        <v-card-text
          style="overflow-y:auto; overflow-x:hidden; position:fixed;"
          class="pa-0"
        >
          <v-layout
            row
            wrap
            fill-height
            justify-center
            align-center
            class="pa-0"
            style="height: 95vh;"
          >
            <v-flex
              v-if="donotpreview === false"
              xs6
              :style="displayloading"
              class="text-center"
            >
              <v-progress-circular
                size="120"
                width="8"
                background-color="light-blue lighten-4"
                :color="color.theme"
                indeterminate
                rounded
                >กำลังเตรียมไฟล์</v-progress-circular
              >
            </v-flex>

            <v-flex
              xs12
              lg12
              :style="displayiframe"
              class="text-center"
              v-if="donotpreview === false"
            >
              <v-layout justify-center>
                <!-- ปุ่มเลื่อนซ้าย -->
                <v-flex
                  class="ml-3 "
                  lg1
                  xs1
                  fill-height
                  justify-center
                  align-center
                  v-if="param_preview !== 'editfileonline'"
                >
                  <div style="position: absolute; top: 50%; left: 4%; z-index: 3;">
                    <v-btn
                      v-if="disableleftclick !== true"
                      @click="fn_sliefile_left()"
                      x-small
                      fab
                      color="black"
                      dark
                    >
                      <v-icon>arrow_back_ios</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
                <!-- หน้าpreview -->
                <v-flex
                  lg10
                  xs10
                  fill-height
                  justify-center
                  align-center
                  id="body"
                >
                  <div v-if="typeviewer === 'image'">
                    <div
                      v-if="this.filedata.inbox_id"
                      oncontextmenu="return false"
                    >
                      <img
                        :src="filearraybuffer"
                        alt="preview"
                        class="modal-contentc testImage"
                      />
                    </div>
                    
                    <div
                      class="ml-0 mr-0"
                      id="imageratio"
                      style="overflow: hidden;"
                      v-else-if="
                        this.filedata.file_permission_2 === '01' ||
                          this.filedata.permission_account === '01'
                      "
                    >
                      <!-- <div :class="`watermarked-test elevation-1`">
                        <p :class="`text-water-marked lean-left`"></p>
                      </div>
                        <img
                          :src="filearraybuffer"
                          alt="preview"
                          class="modal-contentc testImage"
                          style="max-width: 80%; max-height: 80%; position: relative;"
                        /> -->
                      <div
                        fill-height
                        justify-center
                        class=" text-center watermarked testImage"
                        :data-watermark="
                          (
                            dataCitizenProfile.email +
                            ' ' +
                            dataCitizenProfile.first_name_th +
                            '_' +
                            dataCitizenProfile.last_name_th +
                            '_' +
                            today +
                            ' '
                          ).repeat(5)
                        "
                        oncontextmenu="return false"
                      >
                        <img
                          :src="filearraybuffer"
                          alt="preview"
                          class="modal-contentc"
                        />
                      </div>
                    </div>
                    <div v-else oncontextmenu="return false" id="imageratio" style="overflow: hidden;">
                      <img
                          :src="filearraybuffer"
                          alt="preview"
                          class="testImage"
                          style="max-width: 80%; max-height: 80%; position: relative;"
                        />
                    </div>
                    
                    <div v-if="isActiveImageTooling" style="width: 100%; background-color: transparent; position: absolute; left: 0%; bottom: 8%; z-index: 3;" >
                      <v-layout row justify-center align-center>
                        <v-card  class="px-2 py-1" >
                          <v-list-item dense>
                              <v-btn fab x-small :disabled="scalefarenhi === 1 ? true : false" depressed outlined class="mr-2" @click="scaleImage('OUT')">  
                              <v-icon>mdi-magnify-minus-cursor</v-icon>
                              </v-btn>
                              <v-btn fab x-small depressed outlined :disabled="scalefarenhi === 2.0 ? true : false" class="ml-4 mr-2" @click="scaleImage('IN')">
                                <v-icon>mdi-magnify-plus-cursor</v-icon>
                              </v-btn>
                              <v-divider vertical inset></v-divider>
                              <div :style="bgZoomColor" class="py-1 px-4 ml-2 mr-2">
                                <span class="font-weight-bold">
                                  {{ Math.round((scalefarenhi / 1) * 100)  }} %
                                </span>
                              </div>
                              <v-divider vertical inset ></v-divider>
                              <v-btn fab x-small depressed outlined @click="rotateImage('L')" class="ml-4 mr-2" > 
                              <v-icon>mdi-rotate-left-variant</v-icon>
                              </v-btn>
                              <v-btn fab x-small depressed outlined class="ml-4" @click="rotateImage('R')">
                              <v-icon>mdi-rotate-right-variant</v-icon>
                              </v-btn>
                                </v-list-item>
                        </v-card>
                      </v-layout>
                    </div>
                  </div>
                  <div class="ml-0 mr-0" v-else-if="typeviewer === 'pdf'">
                    <!-- <div v-if="screenSize >= 600">
                      <iframe :src="rawbase64" style="height: 90vh; max-width: 99vw; width: 100vw;"></iframe>
                    </div> -->
                    <!-- v-if="this.filedata.file_permission.view_only === True " -->

                    <!-- เช็ค file_permission โดยเช็คจาก view_only เท่ากับ true และ dowload,edit,delete เท่ากับ false-->
                    <div class="ml-0 mr-0" v-if="this.filedata.inbox_id">
                      <div class=" text-center">
                        <!-- <vuePdf
                      v-for="i in pageCount"
                      :key="i"
                      :src="filearraybuffer"
                      :page="i"
                      class="ma-1"
                      style="display; inline-block; width:99vw;"
                    ></vuePdf> -->
                        <iframe
                          id="iframeratio"
                          embedded="true"
                          :src="rawbase64"
                        ></iframe>
                      </div>
                    </div>
                    <div
                      class="ml-0 mr-0"
                      v-else-if="
                        (this.filedata.file_permission_2 === '01' ||
                          this.filedata.permission_account === '01') &&
                          screenSize >= 500
                      "
                    >
                      <div
                        fill-height
                        justify-center
                        class=" text-center"
                        :data-watermark="
                          (
                            dataCitizenProfile.email +
                            ' ' +
                            dataCitizenProfile.first_name_th +
                            '_' +
                            dataCitizenProfile.last_name_th +
                            '_' +
                            today +
                            ' '
                          ).repeat(5)
                        "
                        oncontextmenu="return false"
                      >
                        <!-- แผ่นใสปิดการคัดลอก -->
                        <v-expand-transition v-if="typeviewer !== 'pdf'">
                          <v-overlay
                            absolute
                            color="lighten-1"
                            style="width:1210px;"
                            class="mt-9 mb-1"
                          >
                          </v-overlay>
                        </v-expand-transition>
                        <!-- <vuePdf
                      v-for="i in pageCount"
                      :key="i"
                      :src="filearraybuffer"
                      :page="i"
                      class="ma-1"
                      style="display; inline-block; width:99vw;"
                    ></vuePdf> -->
                        <iframe
                          id="iframeratio"
                          embedded="true"
                          :src="rawbase64"
                        ></iframe>
                      </div>
                    </div>
                    <!-- กรณีไม่เข้าเงื่อนไข -->
                    <div v-else oncontextmenu="return false">
                      <div class=" text-center">
                        <!-- <vuePdf
                      v-for="i in pageCount"
                      :key="i"
                      :src="filearraybuffer"
                      :page="i"
                      class="ma-1"
                      style="display; inline-block; width:99vw;"
                    ></vuePdf> -->
                        <iframe
                          id="iframeratio"
                          embedded="true"
                          :src="rawbase64"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <div
                    align-center
                    class="ml-0 mr-0"
                    v-else-if="typeviewer === 'mp3'"
                  >
                    <vue-plyr controls>
                      <audio
                      >
                        <source
                          :src="link_video"
                          type="audio/mp3"
                        />
                      </audio>
                    </vue-plyr>
                  </div>
                  <div
                    align-center
                    class="ml-0 mr-0"
                    v-else-if="typeviewer === 'mp4' || typeviewer === 'mov'"
                  >
                    <!-- <video-player
                      align-center
                      class=" video-player-box "
                      ref="videoPlayer"
                      :options="playerOptions"
                    >
                    </video-player> -->
                    <vue-plyr>
                      <video
                        controls
                      >
                        <source
                          :src="typeviewer === 'mp4' ? link_video : ''"
                          type="video/mp4"
                        />
                      </video>
                    </vue-plyr>
                    <!-- 
                      customEventName="customstatechangedeventname"
                      @play="onPlayerPlay($event)"
                      @pause="onPlayerPause($event)"
                      @ended="onPlayerEnded($event)"
                      @waiting="onPlayerWaiting($event)"
                      @playing="onPlayerPlaying($event)"
                      @timeupdate="onPlayerTimeupdate($event)"
                      @statechanged="playerStateChanged($event)"
                      @ready="playerReadied" -->
                  </div>
                  <div class="ml-0 mr-0" v-else-if="typeviewer === 'iframe'">
                    <div class="ml-0 mr-0" v-if="this.filedata.inbox_id">
                      <div class=" text-center">
                        <iframe
                          id="iframeratio"
                          embedded="true"
                          :src="rawbase64"
                        ></iframe>
                        <!-- <iframe
                         v-else
                          id="iframeratio"
                          embedded="true"
                          :src="rawbase64"
                        ></iframe> -->
                      </div>
                    </div>
                    <!-- แบบเก่า -->
                    <!-- <div
                      class="ml-0 mr-0"
                      v-else-if="
                        (this.filedata.file_permission_2 === '01' ||
                          this.filedata.permission_account === '01') &&
                          screenSize >= 500
                      "
                    > -->
                    <!-- แบบใหม่ -->
                    <div
                      class="ml-0 mr-0"
                      v-else-if="
                        (this.filedata.file_permission_2 === '01' ||
                          this.filedata.permission_account === '01')
                      "
                    >
                      <div
                        fill-height
                        justify-center
                        class=" text-center watermarked"
                        :data-watermark="
                          (
                            dataCitizenProfile.email +
                            ' ' +
                            dataCitizenProfile.first_name_th +
                            '_' +
                            dataCitizenProfile.last_name_th +
                            '_' +
                            today +
                            ' '
                          ).repeat(5)
                        "
                        oncontextmenu="return false"
                      >
                        <!-- แผ่นใสปิดการคัดลอก -->
                        <!-- <v-expand-transition>
                          <v-overlay
                            absolute
                            color="lighten-1"
                            style="width:1210px;"
                            class="mt-9 mb-1"
                          >
                          </v-overlay>
                        </v-expand-transition> -->
                        <!-- <v-expand-transition>
                          <v-overlay
                            absolute
                            color="red"
                            style="height:40px;"
                            class="position_overlay"
                          >
                          </v-overlay>
                        </v-expand-transition> -->
                        <iframe
                          id="iframeratio"
                          embedded="true"
                          :src="rawbase64"
                        ></iframe>
                      </div>
                    </div>
                    <!-- กรณีไม่เข้าเงื่อนไข -->
                    <div v-else oncontextmenu="return false">
                      <div class=" text-center">
                        <iframe
                          id="iframeratio"
                          embedded="true"
                          :src="rawbase64"
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  <!-- <iframe id="iframeratio" embedded="true" :src="rawbase64" v-else-if="typeviewer === 'iframe'"></iframe> -->
                </v-flex>
                <!-- ปุ่มเลื่อนขวา -->
                <v-flex
                  class="mr-3"
                  lg1
                  xs1
                  fill-height
                  justify-center
                  align-center
                  v-if="param_preview !== 'editfileonline'"
                >
                  <div style="position: absolute; top: 50%; right:4%;">
                    <v-btn
                      v-if="disablerightclick !== true"
                      @click="fn_sliefile_right()"
                      x-small
                      fab
                      color="black"
                      dark
                    >
                      <v-icon>arrow_forward_ios</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              xs11
              :style="displaynotcompatible"
              class="text-center"
              v-if="donotpreview === false"
            >
              <v-layout>
                <v-flex
                  class="ml-3"
                  lg1
                  xs1
                  fill-height
                  justify-center
                  align-center
                >
                  <div style="position: absolute; top: 50%; left:4%;">
                    <v-btn
                      v-if="disableleftclick !== true"
                      @click="fn_sliefile_left()"
                      x-small
                      fab
                      color="black"
                      dark
                    >
                      <v-icon>arrow_back_ios</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
                <v-flex lg10 xs10 fill-height justify-center align-center>
                  <v-icon size="200" v-if="filedata.file_icon" color="grey">{{
                    filedata.file_icon[0]
                  }}</v-icon>
                  <v-icon size="200" v-else color="grey">{{
                    seticon(filedata.data_type)[0]
                  }}</v-icon>
                  <!-- <v-icon size="200" color="grey">mdi-file-cancel</v-icon> -->
                  <br />
                  <br />
                  <!-- <p style="font-size: 25px; color: grey" v-if="filedata.status_encrypt === 'Y'">ไม่สามารถดูไฟล์ได้ เนื่องจากไฟล์มีการเข้ารหัส</p> -->
                  <v-btn
                    class="ma-2"
                    :disabled="!checkfiledata || disableDataStorageOverload || filedata.status_expired === '0'"
                    :color="color.theme"
                    dark
                    @click="downloadfile(filedata)"
                    >Download
                    <v-icon class="pl-2">cloud_download</v-icon></v-btn
                  >
                </v-flex>
                <v-flex
                  class="mr-3"
                  lg1
                  xs1
                  fill-height
                  justify-center
                  align-center
                >
                  <div style="position: absolute; top: 50%; right:4%;">
                    <v-btn
                      v-if="disablerightclick !== true"
                      @click="fn_sliefile_right()"
                      x-small
                      fab
                      color="black"
                      dark
                    >
                      <v-icon>arrow_forward_ios</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs11 class="text-center" v-if="donotpreview === true">
              <v-layout>
                <v-flex
                  class="ml-3"
                  lg1
                  xs1
                  fill-height
                  justify-center
                  align-center
                >
                  <div style="position: absolute; top: 50%; left:4%;">
                    <v-btn
                      v-if="disableleftclick !== true"
                      @click="fn_sliefile_left()"
                      x-small
                      fab
                      color="black"
                      dark
                    >
                      <v-icon>arrow_back_ios</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
                <v-flex lg10 xs10 fill-height justify-center align-center>
                  <v-icon size="200" v-if="filedata.file_icon" color="grey">{{
                    filedata.file_icon[0]
                  }}</v-icon>
                  <v-icon size="200" v-else color="grey">{{
                    seticon(filedata.data_type)[0]
                  }}</v-icon>
                  <!-- <v-icon size="200" color="grey">mdi-file-cancel</v-icon> -->
                  <br />
                  <br />
                  <p style="font-size: 25px; color: grey">
                    {{ $t("donotpreview") }}
                  </p>
                  <!-- <v-btn class="ma-2" :disabled="!checkfiledata" :color="color.theme" dark @click="downloadfile(filedata)"
                >Download <v-icon class="pl-2">cloud_download</v-icon></v-btn
              > -->
                </v-flex>
                <v-flex
                  class="mr-3"
                  lg1
                  xs1
                  fill-height
                  justify-center
                  align-center
                >
                  <div style="position: absolute; top: 50%; right:4%;">
                    <v-btn
                      v-if="disablerightclick !== true"
                      @click="fn_sliefile_right()"
                      x-small
                      fab
                      color="black"
                      dark
                    >
                      <v-icon>arrow_forward_ios</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <div class="text-center ma-2">
            <v-snackbar
              v-model="snackbardowload"
              bottom
              right
              color="white"
              :timeout="timeout"
            >
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short dark flat fab>
                  {{ $t("myinboxPage.dowload.dowloading") }}
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red"
                    text
                    @click="snackbardowload = false"
                    fab
                    x-small
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title>{{ namedowload }}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                    >&nbsp; {{ percentCompleted }}</v-progress-circular
                  >
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- snackbardowload -->
    <dualogalerteditonline
      :show="opendialogalerteditonline"
      @closedialog="fn_closedialogcheckeditonline()"
    ></dualogalerteditonline>
    <dialogsnackbermultidowload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload = false"
      :percent="percent"
      :namedowload="namedowload"
      :btsdownload="btsdownload"
    ></dialogsnackbermultidowload>
    <rightcheckpassword
      :show="opendialog_checklog"
      @closedialog="opendialog_checklog = false, checkcompatible(), $emit('status_createpass', false)"
      :filedata="filedata"
      @closepreview="cancelpassword()"
    />
    <dialogsnackbardowloadfile
      :show="opensnackbardownloadfile"
      @closedialog="opensnackbardownloadfile = false"
      :filedata="file"
      :percen="newpercen"
      :name="namefile"
      :list="new_list"
      :btsdownload="btsdownload"
    ></dialogsnackbardowloadfile>
    <!-- remark TAXBOX ของแต่ละผู้ขาย -->
    <dialogalertremarktaxbox
      v-show="typeinbox === '1'"
      :show="opendialogalertremarktaxbox"
      @closedialog="opendialogalertremarktaxbox = false"
      :taxinvoicedata="filedata_1"
    ></dialogalertremarktaxbox>
  </v-row>
</template>

<style>
.video-js .vjs-tech {
  position: absolute !important;
  top: 5vw !important;
  left: 2.5vw !important;
  width: 90% !important;
  height: 70% !important;
}
/* .video-js{
  width: 50vw!important;
  height: 37vw!important;
} */
.video-js .vjs-big-play-button {
  top: 45%;
  left: 45%;
}
@media only screen and (max-width: 960px) {
  .video-js .vjs-big-play-button {
    font-size: 2em;
  }
}
@media only screen and (max-width: 600px) {
  .video-js .vjs-big-play-button {
    font-size: 1em;
  }
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import vuePdf from "vue-pdf";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import VueCookies from "vue-cookies";
import { log } from 'console';
// import vuePdf from 'pdfvuer'
const rightcheckpassword = () =>
  import("@/components/optional/dialog-rightclickcheckpassword");
const dialogsnackbermultidowload = () =>
  import("../optional/dialog-snackbarmultidowmload");
const dialogsnackbardowloadfile = () =>
  import("../optional/dialog-snackbardownloadfile");
const dualogalerteditonline = () => import("../optional/dialog-alerteditonline.vue");
const dialogalertremarktaxbox = () =>
  import("../optional/dialog-alert-remark-taxbox.vue")
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js'
import 'vue-plyr/dist/vue-plyr.css'

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show", "parentfolder", "filedata_1", "arrayfile","param_preview", "fileSharedFeature", "typeinbox"],
  components: {
    vuePdf,
    rightcheckpassword,
    dialogsnackbermultidowload,
    dialogsnackbardowloadfile,
    videoPlayer,
    dualogalerteditonline,
    dialogalertremarktaxbox,
    VuePlyr
  },
  data: function() {
    return {
      isActiveImageTooling: true,
      isOpenShow_watermark: false,
      fit_ontop_container: false,
      fit_onbottom_container: false,
      fit_onleft_container: false,
      fit_onright_container: false,
      isDragging_image: false,
      isOverTop: false,
      isOverLeft: false,
      isOverTopAndLeft: false,
      rememberScale: 0,
      initialMouseX: 0,
      initialMouseY: 0,
      translate_posX: 0,
      translate_posY: 0,
      overflowLeft: 0,
      overflowTop: 0,
      imageItems: "",
      rotatedegree: 0,
      scalefarenhi: 1,
      checkdownload_cookie:VueCookies.get("feature_onlyoffice"),
      opendialogalerteditonline:false,
      link_video: "",
      playerOptions: {
        // videojs options
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "/static/images/author.jpg",
        height: window.innerHeight - 150,
        width:  window.innerWidth - 70
      },
      opensnackbardownloadfile: false,
      opendialogalertremarktaxbox: false,
      new_list: [],
      btsdownload: false,
      donotpreview: false,
      raw_arrayfile: [],
      disableleftclick: false,
      disablerightclick: false,
      test: true,
      filedata: {},
      objIndex: 0,
      today: new Date().toLocaleDateString(),
      // .substr(0, 10)  .toISOString()
      percent: 0,
      opensnackbarmultidownload: false,
      opendialog_checklog: false,
      namedowload: "",
      timeout: 6000,
      percentCompleted: 0,
      newpercen: 0,
      namefile: "",
      file: {},
      snackbardowload: false,
      datetime: "",
      rawbase64: "",
      displayloading: "",
      displayiframe: "display: none",
      displaynotcompatible: "display: none",
      scale: "page-width",
      compatibletype: "",
      compatible: false,
      pageCount: 0,
      loading: true,
      filearraybuffer: "",
      SizeImage:
        window.innerHeight > window.innerWidth
          ? window.innerWidth
          : window.innerHeight,
      filename: "",
      createprogress: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" },
      ],
      right: null,
      drawer: null,
      sizepdf: 100,
      imagefilescompatible: ["jpg", "jpeg", "png", "gif", "bmp"],
      officefilescompatible: ["xlsx", "pptx", "xls", "ppt", "doc", "docx"],
      extensionsfilescompatible: [
        "jpeg",
        "jpg",
        "png",
        "gif",
        "pdf",
        "xlsx",
        "pptx",
        "xls",
        "ppt",
        "doc",
        "docx",
        "mp4",
        "mov",
        "txt",
        "xml",
        "xlsb",
        "html",
        "csv",
        "bmp",
        "mp3"
      ],
      // "txt", "xml",
      typeviewer: "",
      listremarktaxboxtaxid: [
        "0107537000521",
        // "1798142971048",
        // "0105559156778"
        // "6150328111820"
      ],
      link_music: "",
      currentTime: 0,
    };
  },
  watch: {
    loading: function(newval, oldval) {
      if (this.filedata_1.file_type !== "shortcut") {
        console.log("param",this.param_preview);
        console.log("new", this.filedata_1);
        console.log("newval", newval);
        // this.fn_showallfile();
        console.log("arrayfile", this.arrayfile);
        if (newval == true) {
          this.displayloading = "";
          this.displayiframe = "display: none";
          this.displaynotcompatible = "display: none";
        } else if (newval == false && this.compatible == true) {
          this.displayloading = "display: none";
          this.displayiframe = "";
          this.displaynotcompatible = "display: none";
        } else if (newval == false && this.compatible == false) {
          console.log("ttttteeeeeeee");
          this.displayloading = "display: none";
          this.displayiframe = "display: none";
          this.displaynotcompatible = "";
        }
      } else {
      }
    },
    show: function(value) {
      if (value === false) {
        this.rawbase64 = "";
        this.loading = true;
        this.filearraybuffer = "";
        this.compatibletype = "";
        this.compatible = false;
        this.pageCount = 0;
        this.typeviewer = "";
        this.displayloading = "";
        this.displayiframe = "display: none";
        this.displaynotcompatible = "display: none";
        this.$emit("openDrag");
        //this.closedialog();
      } else {
        console.log("arrayfile show", this.arrayfile);
        this.loading = true;
        //this.checkcompatible();
        this.fn_showallfile();

        this.$emit("closeDrag");
      }
    },
    isOpenShow_watermark: function(val) {
      if(val){
        console.log("citizen watermarked ", Array.from(document.querySelectorAll('.text-water-marked')))
        setTimeout(() => {
          Array.from(document.querySelectorAll('.text-water-marked')).forEach((item) => {
            console.log("whyyyyy not showwowoww")
              if(this.$t('default') === 'en'){
                item.innerHTML = (` Shared By_${this.dataCitizenProfile.first_name_eng+' '+this.dataCitizenProfile.last_name_eng}`).repeat(1000)
              } else {
                item.innerHTML = (` แชร์โดย_${this.dataCitizenProfile.first_name_th+' '+this.dataCitizenProfile.last_name_th}`).repeat(1000)
              }
            
            })
          
        }, 150)
      } else {
        console.log("whyyyyy not")
        setTimeout(() => {
          Array.from(document.querySelectorAll('.text-water-marked')).forEach((item) => {
              item.innerHTML = ''
          })
          
        }, 150)
      }
    }
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
      "storage_usage_biz",
      "feature_onlyoffice",
      "feature_renamedocument",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataCheckPackage",
      "dataStorageMax",
    ]),
    checkfiledata() {
      // console.log("this.filedata.file_permission",this.filedata.file_permission);
      // if(this.filedata.file_permission.download === "True"){
      //   return true
      // }else if(this.filedata.file_permission.view_only === 'True' && this.filedata.file_permission.admin_data === 'False' && this.filedata.file_permission.edit === 'False' && this.filedata.file_permission.download === 'False' && this.filedata.file_permission.delete === 'False'){
      //   return false
      // }else{
      //   return false
      //
      console.log("this.filedata checkfiledata", this.filedata);
      console.log("this.filedata", this.filedata_1);
      console.log("this.donotpreview +", this.donotpreview);
      if (parseInt(this.filedata.cre_dtm) >= 20240101000000 && parseInt(this.filedata.cre_dtm) <= 20240119000000 && this.filedata.sender_id === "0105559156778" && this.dataAccountActive.type === 'Citizen') {
        return false;
      }
      if (this.filedata.inbox_id) {
        return true;
      } else {
        if (
          this.donotpreview == false &&
          this.filedata.file_permission_2 !== "01" &&
          this.filedata.editing_status === "N"
        ) {
          console.log(this.donotpreview);
          console.log("return true1");
          console.log("===", this.filedata.file_permission_2);
          return true;
        } else if (
          this.donotpreview == false &&
          this.filedata.permission_account === "01" &&
          this.filedata.editing_status === "N"
        ) {
          console.log(this.donotpreview);
          console.log("return true");
          console.log("===", this.filedata.file_permission_2);
          return false;
        } else {
          console.log("return false");
          return false;
        }
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          // this.checkcompatible();
          //this.checklastdtm();
          //this.fn_showallfile();

          if (this.param_preview === "editfileonline") {
            this.fn_create_destroy_event(true)
          }
          if (this.listremarktaxboxtaxid.includes(this.filedata_1.sender_id)) {
            this.opendialogalertremarktaxbox = true;
          }
          console.log("filedata MAIIII", this.filedata_1)
          console.log("this.listremarktaxboxtaxid", this.listremarktaxboxtaxid.includes(this.filedata_1.sender_id));
          this.$emit("closeDrag");
          setTimeout(() => {
              this.detectDeviceType()
            }, 200)
        } else {
          // this.closedialog();
          // if (this.param_preview === "editfileonline") {
            // this.fn_create_destroy_event(false);
          // }
          this.isActiveImageTooling = true
          this.isDragging_image= false
          this.initialMouseX= 0
          this.initialMouseY = 0
          this.translate_posX = 0
          this.translate_posY = 0
          this.rememberScale = 0
          this.rotatedegree = 0
          this.scalefarenhi = 1
          this.overflowLeft = 0
          this.overflowTop =  0
          this.fit_onleft_container = false
          this.fit_onbottom_container = false
          this.fit_ontop_container = false
          this.fit_onright_container = false
          this.imageItems = ""
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          // if (this.param_preview === "editfileonline") {
            // this.fn_create_destroy_event(false);
          // }
          //this.closedialog();
        }
      },
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.filename.$dirty) return errors;
      !this.$v.filename.required && errors.push("กรุณากรอกชื่อ ไฟล์/โฟลเดอร์");
      return errors;
    },
    screenSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // player() {
    //   return this.$refs.videoPlayer.player;
    // },
    disableByPackage() {
      // return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? true : false : this.dataCheckPackage.package ? true : false
      return false;
    },
    disableDataStorageOverload() {
      return (
        this.convertstorageToByte(this.storage_usage_biz) >
        this.convertstorageToByte(this.dataStorageMax)
      );
    },
    onlyofficeDomain() {
      let business_feature = this.dataAccountActive.business_info.business_feature;
      let objIndex = business_feature.findIndex((obj) => obj.feature === "only_office");
      return business_feature[objIndex].domain;
    },
    onlyofficeDomain_citizen () {
      return this.fileSharedFeature.domain; // เพราะว่าเป็น obj อยู่แล้วห้ะ
    },
    bgZoomColor () {
      return this.$vuetify.theme.dark ? `border-radius: 20px; padding-top: -10px;` : `border-radius: 20px; padding-top: -10px; background-color: #f0f0f0;`
    }
  },
  methods: {
    touchDetect_event () {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    },
    detectDeviceType() {
      if(['xs', 'sm',  'md'].includes(this.$vuetify.breakpoint.name)){
        this.isActiveImageTooling = false
        return
      } else {
        var userAgent = navigator.userAgent;
        var isMobileOS = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        var isWindows = /Windows NT/i.test(userAgent);
        if((isWindows || /Macintosh/i.test(userAgent)) && !this.touchDetect_event() && !isMobileOS ){ // detect ที่เป็น desktop ทั้ง window และ macos ที่ไม่มี touchscreen และจะต้องไม่ใช่ device 
          this.isActiveImageTooling = true
        } else if (/Macintosh/i.test(userAgent) && this.touchDetect_event()) { // เป็น IOS ที่จะต้อง touchscreen ได้ ซึ่งจะเป็น ipad ทุกรุ่น
          this.isActiveImageTooling = false
        } else {
           this.isActiveImageTooling = true
        }
      }
    },
    scaleImage (status) {
      this.imageItems = document.getElementsByClassName('testImage')[0]
      const canvasId = document.getElementById("imageratio")
      if(status === 'IN'){
        this.scalefarenhi += 0.2
        this.scalefarenhi = Number(this.scalefarenhi.toFixed(1))
      } else {
        this.scalefarenhi -= 0.2
        this.scalefarenhi = Number(this.scalefarenhi.toFixed(1))
      }
      if(this.rememberScale === 0){
        this.imageItems.style.transform = `scale(${this.scalefarenhi}) translate(${0}px, ${0}px) rotate(${this.rotatedegree}deg)`
      } else {
        this.translate_posX = 0
        this.translate_posY = 0
        this.imageItems.style.transform = `scale(${this.scalefarenhi}) translate(${this.translate_posX}px, ${this.translate_posY}px) rotate(${this.rotatedegree}deg)`
      }
      const containerRect = canvasId.getBoundingClientRect();
      const ImageReact = this.imageItems.getBoundingClientRect();
      this.overflowLeft = (containerRect.left - ImageReact.left) + 2
      this.overflowTop = (containerRect.top - ImageReact.top) + 2
      this.isOverTop = (ImageReact.top < containerRect.top)
      this.isOverLeft = (ImageReact.left < containerRect.left)
      this.isOverTopAndLeft = (ImageReact.top < containerRect.top && ImageReact.left < containerRect.left)
      this.NewCreateEventBoundaries(this.imageItems)
    },

    rotateImage (status) {
      const canvasId = document.getElementById("imageratio")
      this.imageItems = document.getElementsByClassName('testImage')[0]
      this.translate_posX = 0
      this.translate_posY = 0
      this.rememberScale = 0
      this.scalefarenhi = 1
      if(status === 'R'){
        this.rotatedegree += 90
        if(this.rotatedegree === 360){
          this.rotatedegree = 0
        }
      } else {
        this.rotatedegree -= 90
        if(this.rotatedegree === -360){
          this.rotatedegree = 0
        }
      }
      
      this.imageItems.style.transform = `rotate(${this.rotatedegree}deg) translate(${this.translate_posX}px, ${this.translate_posY}px)`
      const containerRect = canvasId.getBoundingClientRect();
      const ImageReact = this.imageItems.getBoundingClientRect();
      this.overflowLeft = (containerRect.left - ImageReact.left) + 2
      this.overflowTop = (containerRect.top - ImageReact.top) + 2
      this.isOverTop = (ImageReact.top < containerRect.top)
      this.isOverLeft = (ImageReact.left < containerRect.left)
      this.isOverTopAndLeft = (ImageReact.top < containerRect.top && ImageReact.left < containerRect.left)
      this.NewCreateEventBoundaries(this.imageItems)
    },
    fn_closedialogcheckeditonline(){
      this.opendialogalerteditonline = false;
    },
    disbleIframeClick(event){
      event.preventDefault();
    },
    convertstorageToByte(_storage) {
      console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1024
            : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1048576
            : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1073741824
            : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1099511627776
            : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0]
            : parseFloat(splitStorage[0]);
      }
      return Math.ceil(databyte);
    },
    async fn_load_shortcut(parameter) {
      console.log("file_id", parameter);
      let file_id = "";
      if (parameter) {
        file_id = parameter;
      } else {
        file_id = this.filedata_1.file_id;
      }
      var payload = {
        file_id: file_id,
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      let response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_API +
          "/api/shortcut/open_link_encode",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      console.log(response);
      if (response.data.status === "OK") {
        window.open(response.data.shortcut_link);
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.errorCode + ": " + response.data.errorMessage,
        });
      }
    },
    async fn_printPDF_single() {
      let listfile = "";
      this.displayloading = "";
      this.loading = true;
      let i;
      // for(i = 0 ; i< this.filemultipledownload.length ; i++){
      //   if(this.filemultipledownload[i]["file_type"] !== "pdf"){
      //     Toast.fire({
      //       icon: "error",
      //       title: "โปรดเลือกเฉพาะไฟล์ PDF",
      //     });
      //     notpdf = true;
      //     this.loaddataprogress_print = false;
      //   }else if(this.filemultipledownload[i]["status_lock_data"] === "Y"){
      //     Toast.fire({
      //       icon: "error",
      //       title: "บางไฟล์ที่คุณเลือกมีการล็อกไว้",
      //     });
      //     notpdf = true;
      //     this.loaddataprogress_print = false;
      //   }
      //   else{
      listfile = "&list_file=" + this.filedata.file_id;
      //  //console.log(listfile);
      //   }
      // }
      if (this.filedata.file_type === "pdf" || this.filedata.data_type === "pdf") {
        let auth = await gbfGenerate.generateToken();

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
          "/api/merge_pdf?account_id=" +
          this.dataAccountId +
          "&user_id=" +
          this.dataUsername +
          "&Authorization=" +
          auth.code +
          listfile;
        this.axios.get(url, { responseType: 'blob' }).then((response) => {
          //console.log(response.data.status);
          if (response.data.type === "application/pdf") {
            this.displayloading = "display: none";
            this.loading = false;
            console.log(this.displayloading);
            //  this.filemultipledownload = [];
            //   this.loaddataprogress_print = false;
            let url = window.URL.createObjectURL(response.data);
            window.open(url).print();
          } else {
            this.displayloading = "display: none";
            this.loading = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถพิมพ์ไฟล์เสียได้",
            });
          }
        });
      } else if (this.filedata.file_type ? (['jpeg', 'jpg', 'png', 'gif', 'tiff'].includes(this.filedata.file_type.toLowerCase())) : (['jpeg', 'jpg', 'png', 'gif', 'tiff'].includes(this.filedata.data_type.toLowerCase()))) {
        let auth = await gbfGenerate.generateToken();
        let url = process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/convert/file_to_pdf?id=" + this.filedata.file_id;
        this.axios.get(url, { responseType: 'blob', headers: { Authorization: auth.code } }).then((response) => {
          this.$emit("disloading_print");
          if (response.data.type === "application/pdf") {
            this.$emit("disloading_print");
            let url = window.URL.createObjectURL(response.data);
            window.open(url).print();
          } else {
            this.$emit("disloading_print");
            Toast.fire({
              icon: "error",
              title: this.$t("textprint.filesomethingwrong"),
            });
          }
        });
      } else {
        this.$emit("disloading_print");
        Toast.fire({
          icon: "error",
          title: this.$t("textprint.cannotprint"),
        });
      }
    },
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    fn_clearImageZoomEvent () {
      this.scalefarenhi = 1
      this.rememberScale = 0
      this.rotatedegree = 0
      this.translate_posX = 0
      this.translate_posY = 0
      this.initialMouseX = 0
      this.initialMouseY = 0
      this.overflowTop = 0
      this.overflowLeft = 0
      this.isDragging_image = false
      this.fit_ontop_container = false
      this.fit_onbottom_container = false
      this.fit_onleft_container = false
      this.fit_onright_container = false
      const imageItems = document.getElementsByClassName('testImage')[0]
      imageItems.style.transform = ""
      if(this.screenSize >= 500){
        imageItems.removeEventListener("mousedown", this.fn_mousedown)
        imageItems.removeEventListener("mousemove", this.fn_newmousemoving)
      } else {
        imageItems.removeEventListener("mousedown", this.fn_mousedown)
        imageItems.removeEventListener("touchstart", this.fn_newmousemoving)
      }
      
    },
    fn_sliefile_right() {
      this.donotpreview = false;
      this.checklastdtm();
      //console.log("right",this.objIndex);
      let objIndex = (this.objIndex += 1);
      this.rawbase64 = "";
      this.loading = true;
      this.filearraybuffer = "";
      this.compatibletype = "";
      this.compatible = false;
      this.pageCount = 0;
      this.typeviewer = "";
      this.displayloading = "";
      this.displayiframe = "display: none";
      this.displaynotcompatible = "display: none";
      //console.log("f",objIndex,(this.raw_arrayfile.length-1));
      try{
        this.fn_clearImageZoomEvent()
      } catch (err) {
        console.log("Error Catch ")
      }
      
      if (objIndex === this.raw_arrayfile.length - 1) {
        this.disablerightclick = true;
      } else {
        this.disablerightclick = false;
      }

      if (objIndex === 0) {
        this.disableleftclick = true;
      } else {
        this.disableleftclick = false;
      }
      this.filedata = this.raw_arrayfile[objIndex];
      this.checklastdtm(this.filedata.file_id);
      console.log("this.filedata-------", this.filedata);
      this.checkcompatible();
      // if(this.filedata.file_type === "shortcut"){
      //   this.fn_load_shortcut(this.filedata.file_id)
      // }
    },
    fn_sliefile_left() {
      this.donotpreview = false;
      this.checklastdtm();
      //console.log("left",this.objIndex);
      let objIndex = (this.objIndex -= 1);
      this.rawbase64 = "";
      this.loading = true;
      this.filearraybuffer = "";
      this.compatibletype = "";
      this.compatible = false;
      this.pageCount = 0;
      this.typeviewer = "";
      this.displayloading = "";
      this.displayiframe = "display: none";
      this.displaynotcompatible = "display: none";
      try{
        this.fn_clearImageZoomEvent()
      } catch (err) {
        console.log("Error ")
      }
      if (objIndex === 0) {
        this.disableleftclick = true;
      } else {
        this.disableleftclick = false;
      }
      if (objIndex === this.raw_arrayfile.length - 1) {
        this.disablerightclick = true;
      } else {
        this.disablerightclick = false;
      }
      // objIndex = this.arrayfile.findIndex(
      //   (obj) => obj.file_id === this.filedata.file_id)
      this.filedata = this.raw_arrayfile[objIndex];
      console.log(this.filedata);
      this.checklastdtm(this.filedata.file_id);
      this.checkcompatible();
      //this.fn_showallfile();
      // if(this.filedata.file_type === "shortcut"){
      //   this.fn_load_shortcut(this.filedata.file_id)
      // }
    },
    fn_showallfile() {
      //  if(this.filedata_1.file_type === "shortcut"){
      //    console.log("shortcut");
      //   this.fn_load_shortcut();
      //   }
      this.donotpreview = false;
      //console.log("ggggggggggggggggggg");
      console.log("arrayyyyyyyy", this.arrayfile);
      // console.log("arrayyyyyyyy00",this.filedata_1);
      this.raw_arrayfile = this.arrayfile.filter(
        (type) => type.file_type !== "folder"
      );
      console.log("this.raw_arrayfile", this.raw_arrayfile);
      // if(this.test === true){
      console.log("filedata_1 fn_showallfile", this.filedata_1);
      //console.log(this.raw_arrayfile);
      //console.log(this.objIndex);
      this.objIndex = this.raw_arrayfile.findIndex(
        (obj) => obj.file_id === this.filedata_1.file_id
      );
      // console.log("this.objIndex",this.objIndex);
      if (this.objIndex === -1) {
        this.filedata = this.filedata_1;
      } else {
        this.filedata = this.raw_arrayfile[this.objIndex];
      }
      console.log("this.filedata", this.filedata);
      console.log("filedata +++++++", this.filedata.file_icon);
      this.checklastdtm(this.filedata.file_id);
      // this.checkcompatible();
      console.log(" checkcompatible");
      this.test = false;
      if (this.objIndex === 0) {
        this.disableleftclick = true;
      }
      if (this.objIndex === this.raw_arrayfile.length - 1) {
        this.disablerightclick = true;
      }
      // }
    },
    // disablef12(event){
    //   console.log("event",event);
    //   if(event.keyCode==123){
    //     return false;
    //   return false
    // },
    async checklastdtm(_fileid) {
      let payload = {
        account_id: this.dataAccountId,
        file_id: _fileid,
      };
      // this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/last_view_dtm", payload).then((response) => {
      //   if (response.data.status === "OK") {
      //     console.log("response", response.data.status);
      //     if (this.filedata.status_lock_file === "Y") {
      //       this.opendialog_checklog = true;
      //       console.log("opendialog_checklog", this.opendialog_checklog);
      //     }
      //   } else {
      console.log("testtttt", _fileid);
      //   }
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/last_view_dtm",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("fileeeeeeeeeeeeeeeeeeee", this.filedata);
            if (this.filedata.status_lock_data) {
              if (this.filedata.status_lock_data === "Y") {
                this.opendialog_checklog = true;
                console.log("opendialog_checklog", this.opendialog_checklog);
                // EMIT TO DISABLE AUTO FILL ON SEARCH AND AUTO COMPLETE
                this.$emit("status_createpass", true)
              } else {
                this.checkcompatible();
                console.log("response", response.data);
              }
            } else {
              this.checkcompatible();
              console.log("response", response.data);
            }
            // if((this.param_preview === "editfileonline" || this.param_preview === "editfileonline_expired") && (['04', '05', '06'].includes(this.filedata.file_permission_2)) && (this.filedata.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(this.filedata.file_type.toLowerCase()) || 
            //     this.filedata.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(this.filedata.data_type.toLowerCase())) && this.filedata.status_expired !== '0'){
            //   this.opendialogalerteditonline = true;
            // }
          }
        });
    },
    cancelpassword() {
      this.opendialog_checklog = false;
      this.donotpreview = true;
      this.closedialog();
    },
    checkcompatible() {
      // if((this.param_preview === "editfileonline" || this.param_preview === "editfileonline_expired") && (['04', '05', '06'].includes(this.filedata.file_permission_2)) && (this.filedata.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(this.filedata.file_type.toLowerCase()) || 
      //     this.filedata.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(this.filedata.data_type.toLowerCase())) && this.filedata.status_expired !== '0'){
      // ปิดแจ้งเตือน dialog editonline 
      // if (this.feature_onlyoffice === true){
      //   this.opendialogalerteditonline = true;
      // }
      console.log("exten", this.extensionsfilescompatible);
      if (
        this.$router.app["_route"]["name"] !== "myinbox_sourcesystem" &&
        this.$router.app["_route"]["name"] !== "myoutbox_sourcesystem"
      ) {
        let filename = this.filedata.file_name.split(".");
        if (
          this.extensionsfilescompatible.includes(
            filename[filename.length - 1].toLowerCase()
          ) === true &&
          (![
            "mp4",
            "mov",
            "avi",
            "xvid",
            "mkv",
            "flv",
            "webm",
            "h.264",
            "mp3",
          ].includes(this.filedata.file_type.toLowerCase()) ||
            this.filedata.status_encrypt === "N")
        ) {
          this.compatible = true;
          this.setiframeviewer(filename[filename.length - 1].toLowerCase());
        } else {
          this.compatible = false;
          this.loading = false;
          this.displayloading = "display: none";
          this.displayiframe = "display: none";
          this.displaynotcompatible = "";
        }
      } else {
        let filename = this.filedata.filename.split(".");
        if (
          this.extensionsfilescompatible.includes(
            filename[filename.length - 1].toLowerCase()
          ) === true
        ) {
          this.compatible = true;
          this.setiframeviewer(filename[filename.length - 1].toLowerCase());
        } else {
          this.compatible = false;
          this.loading = false;
          this.displayloading = "display: none";
          this.displayiframe = "display: none";
          this.displaynotcompatible = "";
        }
      }
    },
   async setiframeviewer(type) {
      this.rawbase64 = "";
      let payload = {
        user_id: this.dataUsername,
        file_id: this.filedata.file_id,
      };
      console.log("mp4------", type);
      if (!this.officefilescompatible.includes(type)) {
        if (this.imagefilescompatible.includes(type) === true) {
          this.typeviewer = "image";
          this.setimageviewer();
        } else if (type === "pdf") {
          console.log("pdf");
          this.typeviewer = "pdf";
          this.setpdfviewer();
        } else if (type === "mp4") {
          await this.setmp4viewer(type);
          console.log("this.typeviewer", this.typeviewer);
        } else if (type === "mov") {
          await this.setmp4viewer(type);
          console.log("this.typeviewer", this.typeviewer);
        } else if (type === "mp3") {
          await this.setmp4viewer(type);
          console.log("this.typeviewer", this.typeviewer);
        } 
        // else if (type === "txt"){
        //   this.typeviewer = "iframe";
        //     this.rawbase64 =
        //     // "https://docs.google.com/gview?url=" +
        //     "https://docs.google.com/a/google.com/viewer?url=" +
        //     process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        //     "/api/preview/" +
        //     this.dataUsername +
        //     "/" +
        //     this.filedata.file_id +
        //     "&embedded=true";
        // }
        else {
          // if((this.param_preview === 'editfileonline' || this.param_preview === 'editfileonline_expired') && this.screenSize >= 500){          
          if (this.feature_onlyoffice === true && this.screenSize >= 500){   
            if(['04', '05', '06'].includes(this.filedata.file_permission_2)){
            await this.fn_insert_logpreview();
            }
            await this.getversionfile();
          // this.typeviewer = "iframe";
          // this.rawbase64 =
            // "https://docs.google.com/gview?url=" +
            // "https://docs.google.com/a/google.com/viewer?url=" +
            // process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            // "/api/preview/" +
            // this.dataUsername +
            // "/" +
            // this.filedata.file_id +
            // "&embedded=true";

          // let encodeURI = process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/preview/" + this.dataUsername + "/" + this.filedata.file_id
          // this.rawbase64 =  "/documentserver/index.html?filename=" + (this.filedata.file_name || this.filedata.filename) + "&source=" + encodeURIComponent(encodeURI);
          }else{
            this.typeviewer = "iframe";
            this.rawbase64 =
            // "https://docs.google.com/gview?url=" +
            "https://docs.google.com/a/google.com/viewer?url=" +
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/preview/" +
            this.dataUsername +
            "/" +
            this.filedata.file_id +
            "&embedded=true";

          }
        }
        setTimeout(() => {
          this.loading = false;
        }, 3000);
      } else if (this.imagefilescompatible.includes(type)) {
        this.typeviewer = "image";
        this.setimageviewer();
      } else if (type === "pdf") {
        console.log("pdf");
        this.typeviewer = "pdf";
        this.setpdfviewer();
      } else if (type === "mp4") {
        await this.setmp4viewer(type);
        console.log("this.typeviewer", this.typeviewer);
      } else if (type === "mov") {
        await this.setmp4viewer(type);
        console.log("this.typeviewer", this.typeviewer);
      } else if (type === "mp3") {
        await this.setmp4viewer(type);
        console.log("this.typeviewer", this.typeviewer);
      }  else if (type === "txt"){
        this.typeviewer = "iframe";
          
          // let encodeURI =
          //   process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          //   "/api/preview/" +
          //   this.dataUsername +
          //   "/" +
          //   this.filedata.file_id;

            this.rawbase64 =
            "https://view.officeapps.live.com/op/embed.aspx?&wdStartOn=1&src=" +
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/preview/" +
            this.dataUsername +
            "/" +
            this.filedata.file_id;
      } else {
        if (type === "xls" || type === "doc" || type === "ppt") {
          this.typeviewer = "iframe";
          this.rawbase64 =
            "https://view.officeapps.live.com/op/embed.aspx?&wdStartOn=1&src=" +
            process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            "/api/preview/" +
            this.dataUsername +
            "/" +
            this.filedata.file_id;

        }
        //COND: เปิดไฟล์ office (สิทธิ์ 04 +) แชร์มาจาก jv ที่มี feature onlyoffice เปิดจาก citizen แน่นอน ถ้าหากจาก biz จะเป็น undefined ตลอด
        else if(this.dataAccountActive.type === 'Citizen'){
          if(this.fileSharedFeature !== null && this.fileSharedFeature !== undefined){ 
          if(['04', '05', '06'].includes(this.filedata.file_permission_2)){
              await this.fn_insert_logpreview_citizen_sharedfile();
          }
          await this.getversionfile_citizen_shared();
          } else {
            this.typeviewer = "iframe";
            
            // let encodeURI =
            //   process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
            //   "/api/preview/" +
            //   this.dataUsername +
            //   "/" +
            //   this.filedata.file_id;

              this.rawbase64 =
              "https://view.officeapps.live.com/op/embed.aspx?&wdStartOn=1&src=" +
              process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
              "/api/preview/" +
              this.dataUsername +
              "/" +
              this.filedata.file_id;
          }
        } else {
          if(this.$route.name === 'directorysharefile' || this.$route.name === 'sharedwithme') {
            if(this.fileSharedFeature !== null && this.fileSharedFeature !== undefined){ 
              if(['04', '05', '06'].includes(this.filedata.file_permission_2)){
                  await this.fn_insert_logpreview_citizen_sharedfile();
              }
              await this.getversionfile_citizen_shared();
            } else {
              this.typeviewer = "iframe";
              this.rawbase64 =
              "https://view.officeapps.live.com/op/embed.aspx?&wdStartOn=1&src=" +
              process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
              "/api/preview/" +
              this.dataUsername +
              "/" +
              this.filedata.file_id;
            }
          } else {
            if (this.feature_onlyoffice === true &&  this.screenSize >= 500){ 
          // if((this.param_preview === 'editfileonline' || this.param_preview === 'editfileonline_expired') && this.screenSize >= 500){
          // if (this.feature_onlyoffice === true && this.screenSize >= 500){
            if(['04', '05', '06'].includes(this.filedata.file_permission_2)){
              await this.fn_insert_logpreview();
            }
            await this.getversionfile();
            }else{
                this.typeviewer = "iframe";
                  this.rawbase64 =
                  "https://view.officeapps.live.com/op/embed.aspx?&wdStartOn=1&src=" +
                  process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
                  "/api/preview/" +
                  this.dataUsername +
                  "/" +
                  this.filedata.file_id;

            }
          }
        }
        // if(this.fileSharedFeature !== null && this.fileSharedFeature !== undefined){ 
        //   if(['04', '05', '06'].includes(this.filedata.file_permission_2)){
        //       await this.fn_insert_logpreview_citizen_sharedfile();
        //   }
        //   await this.getversionfile_citizen_shared();
        // } else {
        //   if (this.feature_onlyoffice === true &&  this.screenSize >= 500){ 
        //   // if((this.param_preview === 'editfileonline' || this.param_preview === 'editfileonline_expired') && this.screenSize >= 500){
        //   // if (this.feature_onlyoffice === true && this.screenSize >= 500){
        //     if(['04', '05', '06'].includes(this.filedata.file_permission_2)){
        //       await this.fn_insert_logpreview();
        //     }
        //     await this.getversionfile();
        
        //   console.log("g-hk---g-เเข้าอันนี้");
        //   //test 18/07/66
        //   // this.typeviewer = "iframe";
          
        //   // let encodeURI =
        //   //   process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        //   //   "/api/preview/" +
        //   //   this.dataUsername +
        //   //   "/" +
        //   //   this.filedata.file_id;

        //   //   console.log("this.filedata.file_id",this.filedata.file_id);
        //   // //ใช้ปัจจุบัน
        //   // this.rawbase64 =
        //   //   "/documentserver/index.html?filename=" + 
        //   //   encodeURIComponent(
        //   //     this.filedata.file_name || this.filedata.filename
        //   //   ) + "&file_id=" + encodeURIComponent(this.filedata.file_id) 
        //   //   // ) + "&file_id=123459783294i08"+
        //   //   + "&user_idname=" + encodeURIComponent(this.dataUsername) +
        //   //   "&source=" +
        //   //   encodeURIComponent(encodeURI);

        //   //   console.log(" this.rawbase64",this.rawbase64);
        //   /////////


        //   // this.rawbase64 = "https://docs.google.com/gview?url=" + process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/preview/" +this.dataUsername +"/" + this.filedata.file_id + "&embedded=true";
        
        // //ชั่วคราว
        //   // this.rawbase64 =
        //   //   "https://view.officeapps.live.com/op/embed.aspx?&wdStartOn=1&src=" +
        //   //   process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        //   //   "/api/preview/" +
        //   //   this.dataUsername +
        //   //   "/" +
        //   //   this.filedata.file_id;
        //   }else{
        //     this.typeviewer = "iframe";
            
        //     // let encodeURI =
        //     //   process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        //     //   "/api/preview/" +
        //     //   this.dataUsername +
        //     //   "/" +
        //     //   this.filedata.file_id;

        //       this.rawbase64 =
        //       "https://view.officeapps.live.com/op/embed.aspx?&wdStartOn=1&src=" +
        //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        //       "/api/preview/" +
        //       this.dataUsername +
        //       "/" +
        //       this.filedata.file_id;

        //   }
        // }
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    async setimageviewer() {
      let payload = {
        user_id: this.dataUsername,
        file_id: this.filedata.file_id,
        account_id: this.dataAccountId
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file",
          payload,
          {
            headers: { Authorization: auth.code },
            withCredentials: false,
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const buffer = Buffer.from(response.data, "base64");
          let u8 = new Uint8Array(buffer);
          let b64encoded = btoa(
            [].reduce.call(
              new Uint8Array(buffer),
              function(p, c) {
                return p + String.fromCharCode(c);
              },
              ""
            )
          );
          let mimetype = "image/png";
          this.filearraybuffer = "data:" + mimetype + ";base64," + b64encoded;
          setTimeout(() => {
            this.loading = false;
            this.isOpenShow_watermark = true
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดูตัวอย่างไฟล์นี้ได้",
          });
        });
      // รูปที่ไม่มีสิทธิ์ดาวโหลดจะคลิ๊กขวาไม่ขึ้น
      if (!this.filedata.inbox_id) {
        if (this.filedata.file_permission_2 === "01") {
          document.addEventListener("contextmenu", (event) => {
            event.preventDefault();
          });
        }
      }
    },
    async setpdfviewer() {
      console.log("setpdfviewer========");
      console.log("this.filedata ==== ", this.filedata.url_file);
      let payload = {
        user_id: this.dataUsername,
        file_id: this.filedata.file_id,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("dialogpreview--", this.filedata);
      // checkfile
      if (!this.filedata.inbox_id) {
        if (
          this.filedata.file_permission_2 !== undefined ||
          this.filedata.file_permission_2 !== null
        ) {
          if (this.filedata.file_permission_2 !== "01") {
            console.log("เข้า 4");
            // this.loading = false;
            let url;
            if (
              this.filedata.url_file === undefined ||
              this.filedata.url_file === null ||
              this.filedata.url_file === ""
            ) {
              url =
                process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
                "/api/v2/download_file?user_id=" +
                this.dataUsername +
                "&file_id=" +
                this.filedata.file_id +
                "&account_id=" + this.dataAccountId;
            } else {
              url = this.filedata.url_file;
            }

            //console.log("เข้าา",url);
            this.rawbase64 =
              process.env.VUE_APP_UI +
              "/extension_pdfjs/viewer.html?file=" +
              encodeURIComponent(url);

            setTimeout(() => {
              this.loading = false;
            }, 1000);

            console.log("test", url);
            // this.axios
            //   .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file", payload, {
            //     onDownloadProgress: (progressEvent) => {
            //       let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            //       if (progresspercent >= 100) {
            //         this.loading = false;
            //       } else {
            //         this.loading = true;
            //       }
            //     },

            //     withCredentials: false,
            //     responseType: "arraybuffer",
            //   })
            //   .then((response) => {
            //     this.loading = false;
            //     const buffer = Buffer.from(response.data, "base64");
            //     let u8 = new Uint8Array(buffer);
            //     let b64encoded = btoa(
            //       [].reduce.call(
            //         new Uint8Array(buffer),
            //         function(p, c) {
            //           return p + String.fromCharCode(c);
            //         },
            //         ""
            //       )
            //     );

            //     let mimetype = "application/pdf";
            //     this.rawbase64 = "data:" + mimetype + ";base64," + b64encoded;
            //     var loadingTask = vuePdf.createLoadingTask("data:" + mimetype + ";base64," + b64encoded);

            //     this.filearraybuffer = loadingTask;
            //     this.filearraybuffer.promise.then((vuePdf) => {
            //       this.pageCount = vuePdf.numPages;
            //     });
            //     //  console.log("qw", this.rawbase64);
            //     // setTimeout(() => {
            //     //   this.loading = false;
            //     // }, 1000);
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //     setTimeout(() => {
            //       this.loading = false;
            //     }, 1000);
            //     Toast.fire({
            //       icon: "error",
            //       title: "ไม่สามารถดูตัวอย่างไฟล์นี้ได้",
            //     });
            //   });
          } else if (this.filedata.file_permission_2 === "01") {
            if (this.filedata.file_type === "pdf") {
              console.log("เข้าาาาาาาาาาาาาา 01 pdf ");
              let url =
                process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
                "/api/v1/merge/downloads_file?user_id=" +
                this.dataUsername +
                "&file_id=" +
                this.filedata.file_id +
                "&account_id=" +
                this.dataAccountId +
                "&language=" +
                this.$t("default");

              this.rawbase64 =
                process.env.VUE_APP_UI +
                "/extension_pdfjs/viewer.html?file=" +
                encodeURIComponent(url);

              setTimeout(() => {
                this.loading = false;
              }, 1000);
              console.log("test", url);
              // https://uatbox-dedicate.one.th/downloads/api/v1/merge/downloads_file?file_id=...&account_id=...&user_id=...&language=th
            } else {
              console.log("เข้า 5");
              let url =
                process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
                "/api/v2/download_file?user_id=" +
                this.dataUsername +
                "&file_id=" +
                this.filedata.file_id +
                "&account_id=" + this.dataAccountId;
              // let url =
              //   process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              //   "/api/download_with_watermark_v2?user_id=" +
              //   this.dataUsername +
              //   "&file_id=" +
              //   this.filedata.file_id;
              this.rawbase64 =
                process.env.VUE_APP_UI +
                "/extension_pdfjs/viewer.html?file=" +
                encodeURIComponent(url);
              setTimeout(() => {
                this.loading = false;
              }, 1000);
              // this.axios
              //   .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_with_watermark", payload, {
              //     withCredentials: false,
              //     responseType: "arraybuffer",
              //   })
              //   .then((response) => {
              //     this.loading = false;
              //     const buffer = Buffer.from(response.data, "base64");
              //     let u8 = new Uint8Array(buffer);
              //     let b64encoded = btoa(
              //       [].reduce.call(
              //         new Uint8Array(buffer),
              //         function(p, c) {
              //           return p + String.fromCharCode(c);
              //         },
              //         ""
              //       )
              //     );

              //     let mimetype = "application/pdf";
              //     this.rawbase64 = "data:" + mimetype + ";base64," + b64encoded;
              //     var loadingTask = vuePdf.createLoadingTask("data:" + mimetype + ";base64," + b64encoded);

              //     this.filearraybuffer = loadingTask;
              //     this.filearraybuffer.promise.then((vuePdf) => {
              //       this.pageCount = vuePdf.numPages;
              //     });
              //     //  console.log("qw", this.rawbase64);
              //     // setTimeout(() => {
              //     //   this.loading = false;
              //     // }, 1000);
              //   })
              //   .catch((error) => {
              //     console.log(error);
              //     setTimeout(() => {
              //       this.loading = false;
              //     }, 1000);
              //     Toast.fire({
              //       icon: "error",
              //       title: "ไม่สามารถดูตัวอย่างไฟล์นี้ได้",
              //     });
              // });
            }
          } else {
            //  this.loading = false;
            let url =
              process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
              "/api/v2/download_file?user_id=" +
              this.dataUsername +
              "&file_id=" +
              this.filedata.file_id +
              "&account_id=" + this.dataAccountId;
            // let url =
            //   process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            //   "/api/download_with_watermark_v2?user_id=" +
            //   this.dataUsername +
            //   "&file_id=" +
            //   this.filedata.file_id;

            this.rawbase64 =
              process.env.VUE_APP_UI +
              "/extension_pdfjs/viewer.html?file=" +
              encodeURIComponent(url);
            setTimeout(() => {
              this.loading = false;
            }, 1000);
            // this.axios
            //   .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_with_watermark", payload, {
            //     withCredentials: false,
            //     responseType: "arraybuffer",
            //   })
            //   .then((response) => {
            //     this.loading = false;
            //     const buffer = Buffer.from(response.data, "base64");
            //     let u8 = new Uint8Array(buffer);
            //     let b64encoded = btoa(
            //       [].reduce.call(
            //         new Uint8Array(buffer),
            //         function(p, c) {
            //           return p + String.fromCharCode(c);
            //         },
            //         ""
            //       )
            //     );

            //     let mimetype = "application/pdf";
            //     this.rawbase64 = "data:" + mimetype + ";base64," + b64encoded;
            //     var loadingTask = vuePdf.createLoadingTask("data:" + mimetype + ";base64," + b64encoded);

            //     this.filearraybuffer = loadingTask;
            //     this.filearraybuffer.promise.then((vuePdf) => {
            //       this.pageCount = vuePdf.numPages;
            //     });
            //  console.log("qw", this.rawbase64);
            // setTimeout(() => {
            //   this.loading = false;
            // }, 1000);
            //     })
            //     .catch((error) => {
            //       console.log(error);
            //       setTimeout(() => {
            //         this.loading = false;
            //       }, 1000);
            //       Toast.fire({
            //         icon: "error",
            //         title: "ไม่สามารถดูตัวอย่างไฟล์นี้ได้",
            //       });
            //     });
          }
        } else {
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
                "/api/download_with_watermark_v2",
              payload,
              {
                headers: { Authorization: auth.code },
                withCredentials: false,
                responseType: "arraybuffer",
              }
            )
            .then((response) => {
              this.loading = false;
              const buffer = Buffer.from(response.data, "base64");
              let u8 = new Uint8Array(buffer);
              let b64encoded = btoa(
                [].reduce.call(
                  new Uint8Array(buffer),
                  function(p, c) {
                    return p + String.fromCharCode(c);
                  },
                  ""
                )
              );

              let mimetype = "application/pdf";
              this.rawbase64 = "data:" + mimetype + ";base64," + b64encoded;
              var loadingTask = vuePdf.createLoadingTask(
                "data:" + mimetype + ";base64," + b64encoded
              );

              this.filearraybuffer = loadingTask;
              this.filearraybuffer.promise.then((vuePdf) => {
                this.pageCount = vuePdf.numPages;
              });
              //  console.log("qw", this.rawbase64);
              // setTimeout(() => {
              //   this.loading = false;
              // }, 1000);
            })
            .catch((error) => {
              console.log(error);
              setTimeout(() => {
                this.loading = false;
              }, 1000);
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดูตัวอย่างไฟล์นี้ได้",
              });
            });
        }
      } else {
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file_inbox?user_id=" +
          // "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          this.filedata.file_id;

        this.rawbase64 =
          process.env.VUE_APP_UI +
          "/extension_pdfjs/viewer.html?file=" +
          encodeURIComponent(url);

        setTimeout(() => {
          this.loading = false;
        }, 1000);
        console.log("test", url);
        // this.axios
        //   .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file", payload, {
        //     onDownloadProgress: (progressEvent) => {
        //       let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        //       if (progresspercent >= 100) {
        //         this.loading = false;
        //       } else {
        //         this.loading = true;
        //       }
        //     },

        //     withCredentials: false,
        //     responseType: "arraybuffer",
        //   })
        //   .then((response) => {
        //     this.loading = false;
        //     const buffer = Buffer.from(response.data, "base64");
        //     let u8 = new Uint8Array(buffer);
        //     let b64encoded = btoa(
        //       [].reduce.call(
        //         new Uint8Array(buffer),
        //         function(p, c) {
        //           return p + String.fromCharCode(c);
        //         },
        //         ""
        //       )
        //     );

        //     let mimetype = "application/pdf";
        //     this.rawbase64 = "data:" + mimetype + ";base64," + b64encoded;
        //     var loadingTask = vuePdf.createLoadingTask("data:" + mimetype + ";base64," + b64encoded);

        //     this.filearraybuffer = loadingTask;
        //     this.filearraybuffer.promise.then((vuePdf) => {
        //       this.pageCount = vuePdf.numPages;
        //     });
        //     //  console.log("qw", this.rawbase64);
        //     // setTimeout(() => {
        //     //   this.loading = false;
        //     // }, 1000);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     setTimeout(() => {
        //       this.loading = false;
        //     }, 1000);
        //     Toast.fire({
        //       icon: "error",
        //       title: "ไม่สามารถดูตัวอย่างไฟล์นี้ได้",
        //     });
        //   });
      }
    },
    async setmp4viewer(type) {
      console.log("setmp4viewer");
      let payload = {
        file_id: this.filedata.file_id,
      };
      //let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/view_video",
          payload,
          {}
        )
        .then((response) => {
          //console.log("response",response);
          if (response.data.status === "OK") {
            this.link_video =  response.data.result;
            this.playerOptions.sources[0]["src"] = this.link_video;
            setTimeout(() => {
              this.typeviewer = type;
              this.displayloading = "display: none";
              this.displayiframe = "";
            }, 3000);
          }
        })
        .catch((error) => {});
    },
    async getversionfile() {
      console.log("deleterelation");
        var payload = {
          // account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: this.filedata.file_id,
        };
        this.axios
          .post(this.onlyofficeDomain + "/document_editor/api/v1/get/onlyoffice_version", payload )
          // .post("https://uatbox-dedicate.one.th/test_uat/api/v1/get/onlyoffice_version", payload)
         
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              // let file_id_online = VueCookies.get("file");
              console.log("onlyoff",response.data);
              var version_file = response.data.data.version_id;
              this.typeviewer = "iframe";
              let encodeURI =
                process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
                "/api/preview/" +
                this.dataUsername +
                // "/" + file_id_online;
                "/" + this.filedata.file_id;

                console.log("this.filedata.file_id",this.filedata.file_id);
                console.log("result",this.dataAccountActive.business_info.business_feature);
                let business_feature = this.dataAccountActive.business_info.business_feature;
                
                  let objIndex = business_feature.findIndex( (obj) => obj.feature === "only_office" );                  
                  console.log("objIndex",objIndex);
                  console.log("domain",business_feature[objIndex].domain)
                  console.log("feature",business_feature[objIndex].feature)
                  console.log("feature_status",business_feature[objIndex].feature_status)

              //ใช้ปัจจุบัน
              let filetypelower
              if (!this.filedata.filename === undefined) {
                let splitfilename = this.filedata.filename.split(".");
                filetypelower = this.filedata.filename.split('.').slice(0, -1).join('.') + "." + splitfilename[splitfilename.length-1].toLowerCase()
              } else {
                let splitfilename = this.filedata.file_name.split(".");
                filetypelower = this.filedata.file_name.split('.').slice(0, -1).join('.') + "." + splitfilename[splitfilename.length-1].toLowerCase()
              }
              console.log("filetypelower", filetypelower);
              this.rawbase64 =
                "/documentserver/index.html?filename=" + 
                encodeURIComponent(
                  // this.filedata.file_name || this.filedata.filename
                  filetypelower
                ) + "&file_id=" + encodeURIComponent(this.filedata.file_id) 
                 + "&version_id=" + encodeURIComponent(version_file)
                + "&user_idname=" + encodeURIComponent(this.dataUsername) 
                +"&first_name_th=" + encodeURIComponent(this.dataCitizenProfile.first_name_th) 
                +"&last_name_th=" + encodeURIComponent(this.dataCitizenProfile.last_name_th)
                +"&first_name_eng=" + encodeURIComponent(this.dataCitizenProfile.first_name_eng)
                +"&last_name_eng=" + encodeURIComponent(this.dataCitizenProfile.last_name_eng)
                +"&permission2=" + encodeURIComponent(this.filedata.file_permission_2)
                +"&editing_status=" + encodeURIComponent(this.filedata.editing_status)
                +"&language=" + encodeURIComponent(localStorage.getItem("lang"))
                +"&domain=" + encodeURIComponent(business_feature[objIndex].domain)
                +"&feature=" + encodeURIComponent(business_feature[objIndex].feature)
                +"&feature_status=" + encodeURIComponent(business_feature[objIndex].feature_status)
                +"&preview_type=" + encodeURIComponent(this.param_preview)
                +"&status_expired=" + encodeURIComponent(this.filedata.status_expired)
                + "&source=" + encodeURIComponent(encodeURI);

                console.log(" this.rawbase64",this.rawbase64);
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: error,
            });
          });
      
    },
    // ของ citizen shared file only office สิทธิ์ 04+
    async getversionfile_citizen_shared() {
        var payload = {
          // account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: this.filedata.file_id,
        };
        this.axios
          .post(this.onlyofficeDomain_citizen + "/document_editor/api/v1/get/onlyoffice_version", payload )
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              let file_id_online = VueCookies.get("file");
              var version_file = response.data.data.version_id;
              this.typeviewer = "iframe";
              let encodeURI =
                process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
                "/api/preview/" +
                this.dataUsername +
                "/" + file_id_online;
               
              //ใช้ปัจจุบัน
              let filetypelower
              if (!this.filedata.filename === undefined) {
                let splitfilename = this.filedata.filename.split(".");
                filetypelower = this.filedata.filename.split(splitfilename[splitfilename.length-1])[0] + splitfilename[splitfilename.length-1].toLowerCase()
              } else {
                let splitfilename = this.filedata.file_name.split(".");
                filetypelower = this.filedata.file_name.split(splitfilename[splitfilename.length-1])[0] + splitfilename[splitfilename.length-1].toLowerCase()
              }
              this.rawbase64 =
                "/documentserver/index.html?filename=" + 
                encodeURIComponent(
                  // this.filedata.file_name || this.filedata.filename
                  filetypelower
                ) + "&file_id=" + encodeURIComponent(this.filedata.file_id) 
                 + "&version_id=" + encodeURIComponent(version_file)
                + "&user_idname=" + encodeURIComponent(this.dataUsername) 
                +"&first_name_th=" + encodeURIComponent(this.dataCitizenProfile.first_name_th) 
                +"&last_name_th=" + encodeURIComponent(this.dataCitizenProfile.last_name_th)
                +"&first_name_eng=" + encodeURIComponent(this.dataCitizenProfile.first_name_eng)
                +"&last_name_eng=" + encodeURIComponent(this.dataCitizenProfile.last_name_eng)
                +"&permission2=" + encodeURIComponent(this.filedata.file_permission_2)
                +"&editing_status=" + encodeURIComponent(this.filedata.editing_status)
                +"&language=" + encodeURIComponent(localStorage.getItem("lang"))
                +"&domain=" + encodeURIComponent(this.fileSharedFeature.domain)
                +"&feature=" + encodeURIComponent(this.fileSharedFeature.feature)
                +"&feature_status=" + encodeURIComponent(this.fileSharedFeature.feature_status)
                +"&preview_type=" + encodeURIComponent(this.param_preview)
                +"&status_expired=" + encodeURIComponent(this.filedata.status_expired)
                + "&source=" + encodeURIComponent(encodeURI);

                console.log(" this.rawbase64",this.rawbase64);
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: error,
            });
          });
      
    },
    async getpreview() {
      let payload = {
        user_id: this.dataUsername,
        file_id: this.filedata.file_id,
        account_id: this.dataAccountId
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file",
        payload,
        {
          withCredentials: false,
          responseType: "arraybuffer",
          headers: { Authorization: auth.code },
        }
      );
      try {
        const buffer = Buffer.from(response.data, "base64");
        let u8 = new Uint8Array(buffer);
        let b64encoded = btoa(
          [].reduce.call(
            new Uint8Array(buffer),
            function(p, c) {
              return p + String.fromCharCode(c);
            },
            ""
          )
        );
        let mimetype = "image/png";
        // console.log(b64encoded);

        this.filearraybuffer = "data:" + mimetype + ";base64," + b64encoded;
        this.loading = false;
      } catch (ex) {
        console.log(ex);
      }
    },
    async getpreviewpdf(type) {
      this.rawbase64 = "";
      let payload = {
        user_id: this.dataUsername,
        file_id: this.filedata.file_id,
      };
      // console.log(payload);
      var extensionfile = ["xlsx", "pptx", "xls", "ppt", "doc", "docx"];
      var n = extensionfile.includes(type, 0);

      if (n === true) {
        this.rawbase64 =
          "https://view.officeapps.live.com/op/embed.aspx?&wdStartOn=1&src=" +
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/preview/" +
          this.dataUsername +
          "/" +
          this.filedata.file_id;
      } else {
        console.log("เข้า getpreviewpdf ");
        this.rawbase64 =
          "https://docs.google.com/viewer?url=" +
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/preview/" +
          this.dataUsername +
          "/" +
          this.filedata.file_id +
          "&embedded=true";
        // this.rawbase64 = 'https://view.officeapps.live.com/op/view.aspx?src='+ process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/preview/" + this.dataUsername + '/'+ this.filedata.file_id
      }
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },

    zoomin() {
      this.sizepdf = this.sizepdf - 10;
      document.getElementById("zoompdf").style.width = this.sizepdf + "%";
    },
    fn_calculate_size(_sizefilebyte) {
      console.log("ขนาดไฟล์เป็น GB", (_sizefilebyte / 1073741824).toFixed(2));
      return parseFloat((_sizefilebyte / 1073741824).toFixed(2));
    },
    async downloadfile(currentfile) {
      let checkfilesize = this.fn_calculate_size(currentfile.file_size);
      if (currentfile.inbox_id) {
        console.log(currentfile);
        this.downloadfile_inbox(currentfile);
      } else {
        this.percent = 0;
        this.btsdownload = true;
        console.log("currentfile", currentfile);
        if (currentfile.file_type === "folder") {
          Toast.fire({
            type: "warning",
            title: "Coming Soon !!",
          });
        } else {
          if (
            currentfile.file_type === "pdf" &&
            currentfile.file_permission_2 === "01"
          ) {
            // https://uatbox-dedicate.one.th/downloads/api/v1/merge/downloads_file?file_id=...&account_id=...&user_id=...&language=th
            let payload = {
              account_id: this.dataAccountId,
              user_id: this.dataUsername,
              file_id: currentfile.file_id,
            };
            let url =
              process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
              "/api/v1/merge/downloads_file?user_id=" +
              this.dataUsername +
              "&file_id=" +
              currentfile.file_id +
              "&account_id=" +
              this.dataAccountId +
              "&language=" +
              this.$t("default");
            // this.snackbardowload = true;
            if (checkfilesize < 1.0) {
              this.opensnackbarmultidownload = true;
              let auth = await gbfGenerate.generateToken();
              this.axios
                .get(url, {
                  headers: { Authorization: auth.code },
                  onDownloadProgress: (progressEvent) => {
                    let progresspercent = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    console.log(this.percentCompleted);
                    this.namedowload = currentfile.file_name;
                    if (progresspercent === 95) {
                      this.percentCompleted = 95;
                    } else {
                      this.percentCompleted = progresspercent;
                    }
                    this.percent = this.percentCompleted;
                  },
                  withCredentials: false,
                  responseType: "arraybuffer",
                })
                .then((response) => {
                  // if (response.statusText === "OK") {
                  if (response.status === 200) {
                    if (
                      parseInt(response.headers["content-lenght"]) < 700 &&
                      (response.headers["content-type"]) ===
                        "application/json"
                    ) {
                      this.opensnackbarmultidownload = false;
                      let res_error = gbfdecodearraybuffer.decodearraybuffer(
                        response.data
                      );
                      Toast.fire({
                        icon: "error",
                        title: res_error.errorMessage,
                      });
                    } else {
                      this.percentCompleted = 100;
                      this.percent = this.percentCompleted;
                      this.btsdownload = false;

                      let headers = response.headers;
                      let blob = new Blob([response.data], {
                        type: headers["content-type"],
                      });
                      let link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = currentfile.file_name;
                      link.click();

                      // this.namedowload = currentfile.file_name;
                      // const blob = new Blob([response.data]);
                      // const content = response.headers["content-type"];
                      // saveAs(blob, currentfile.file_name);

                      setTimeout(() => {
                        this.opensnackbarmultidownload = false;
                        this.opensnackbar = false;
                      }, 2500);
                    }
                  } else {
                    this.opensnackbarmultidownload = false;
                    Toast.fire({
                      icon: "error",
                      title: this.$t("toast.cannotconnectonebox"),
                    });
                  }
                })
                .catch((error) => {
                  this.btsdownload = false;
                  this.opensnackbarmultidownload = true;
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false;
                  }, 3000);
                  Toast.fire({
                    icon: "error",
                    title:
                      "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
                  });
                  console.log(error);
                });
            } else {
              this.opensnackbardownloadfile = true;
              this.btsdownload = true;
              let timeout = checkfilesize * 4000;
              window.location.assign(url);
              setTimeout(() => {
                this.opensnackbardownloadfile = false;
              }, timeout);
            }
          // แบบเดิม /api/v2/download_file?user_id=
    //       } else {
    //         console.log("เข้า 2");
    //         let payload = {
    //           account_id: this.dataAccountId,
    //           user_id: this.dataUsername,
    //           file_id: currentfile.file_id,
    //         };
    //         let url =
    //           process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //           "/api/v2/download_file?user_id=" +
    //           this.dataUsername +
    //           "&file_id=" +
    //           currentfile.file_id +
    //           "&account_id=" + this.dataAccountId;
    //         // this.snackbardowload = true;
    //         if (checkfilesize < 1.0) {
    //           this.opensnackbarmultidownload = true;
    //           let auth = await gbfGenerate.generateToken();
    //           this.axios
    //             .get(url, {
    //               headers: { Authorization: auth.code },
    //               onDownloadProgress: (progressEvent) => {
    //                 let progresspercent = Math.round(
    //                   (progressEvent.loaded * 100) / progressEvent.total
    //                 );
    //                 console.log(this.percentCompleted);
    //                 this.namedowload = currentfile.file_name;
    //                 if (progresspercent === 95) {
    //                   this.percentCompleted = 95;
    //                 } else {
    //                   this.percentCompleted = progresspercent;
    //                 }
    //                 this.percent = this.percentCompleted;
    //               },
    //               withCredentials: false,
    //               responseType: "arraybuffer",
    //             })
    //             .then((response) => {
    //               // if (response.statusText === "OK") {
    //               if (response.status === 200) {
    //                 try {
    //                   let type = currentfile.file_name.split(".");
    //                   type = type[type.length - 1].toLowerCase();
    //                   let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
    //                   if (res_error.status === "ER" && type !== "json") {
    //                     this.opensnackbarmultidownload = false;
    //                     this.opensnackbar = false;
    //                     Toast.fire({
    //                       icon: "error",
    //                       title: res_error.errorMessage,
    //                     });
    //                   } else {
    //                     this.percentCompleted = 100;
    //                     this.percent = this.percentCompleted;
    //                     this.btsdownload = false;

    //                     let headers = response.headers;
    //                     let blob = new Blob([response.data], {
    //                       type: headers["content-type"],
    //                     });
    //                     let link = document.createElement("a");
    //                     link.href = window.URL.createObjectURL(blob);
    //                     link.download = currentfile.file_name;
    //                     link.click();
    //                     setTimeout(() => {
    //                       this.opensnackbarmultidownload = false;
    //                       this.opensnackbar = false;
    //                     }, 2500);
    //                   }
    //                 } catch (error) {
    //                   console.log("error", error);
    //                   this.percentCompleted = 100;
    //                   this.percent = this.percentCompleted;
    //                   this.btsdownload = false;

    //                   let headers = response.headers;
    //                   let blob = new Blob([response.data], {
    //                     type: headers["content-type"],
    //                   });
    //                   let link = document.createElement("a");
    //                   link.href = window.URL.createObjectURL(blob);
    //                   link.download = currentfile.file_name;
    //                   link.click();
    //                   setTimeout(() => {
    //                     this.opensnackbarmultidownload = false;
    //                     this.opensnackbar = false;
    //                   }, 2500);
    //                 }
    //                 // if (
    //                 //   parseInt(response.headers["content-lenght"]) < 700 &&
    //                 //   parseInt(response.headers["content-type"]) ===
    //                 //     "application/json"
    //                 // ) {
    //                 //   this.opensnackbarmultidownload = false;
    //                 //   let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //                 //     response.data
    //                 //   );
    //                 //   Toast.fire({
    //                 //     icon: "error",
    //                 //     title: res_error.errorMessage,
    //                 //   });
    //                 // } else {
    //                 //   this.percentCompleted = 100;
    //                 //   this.percent = this.percentCompleted;
    //                 //   this.btsdownload = false;

    //                 //   let headers = response.headers;
    //                 //   let blob = new Blob([response.data], {
    //                 //     type: headers["content-type"],
    //                 //   });
    //                 //   let link = document.createElement("a");
    //                 //   link.href = window.URL.createObjectURL(blob);
    //                 //   link.download = currentfile.file_name;
    //                 //   link.click();

    //                 //   // this.namedowload = currentfile.file_name;
    //                 //   // const blob = new Blob([response.data]);
    //                 //   // const content = response.headers["content-type"];
    //                 //   // saveAs(blob, currentfile.file_name);

    //                 //   setTimeout(() => {
    //                 //     this.opensnackbarmultidownload = false;
    //                 //     this.opensnackbar = false;
    //                 //   }, 2500);
    //                 // }
    //               } else {
    //                 this.opensnackbarmultidownload = false;
    //                 this.opensnackbar = false;
    //                 Toast.fire({
    //                   icon: "error",
    //                   title: this.$t("toast.cannotconnectonebox"),
    //                 });
    //               }
    //             })
    //             .catch((error) => {
    //               this.btsdownload = false;
    //               this.opensnackbarmultidownload = true;
    //               setTimeout(() => {
    //                 this.opensnackbarmultidownload = false;
    //               }, 3000);
    //               Toast.fire({
    //                 icon: "error",
    //                 title:
    //                   "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
    //               });
    //               console.log(error);
    //             });
    //         } else {
    //           this.opensnackbardownloadfile = true;
    //           this.btsdownload = true;
    //           let timeout = checkfilesize * 4000;
    //           window.location.assign(url);
    //           setTimeout(() => {
    //             this.opensnackbardownloadfile = false;
    //           }, timeout);
    //         }
    //       }
    //     }
    //   }
    // },
          // แบบใหม่ /api/v1/download_file_s3?user_id=
          } else {
            console.log("เข้า 2");
            let payload = {
              account_id: this.dataAccountId,
              user_id: this.dataUsername,
              file_id: currentfile.file_id,
            };
            let url =
              process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
              // "/api/v2/download_file?user_id=" +
              "/api/v1/download_file_s3?user_id=" +
              this.dataUsername +
              "&file_id=" +
              currentfile.file_id +
              "&account_id=" + this.dataAccountId;
            // this.snackbardowload = true;
            // if (checkfilesize < 1.0) {
          console.log(currentfile.status_encrypt);
          if(currentfile.status_encrypt === "Y"){      
            this.opensnackbar = true;
            this.new_list = this.newlist;
            let auth = await gbfGenerate.generateToken();
            console.log("น้อยกว่า 1 GB");
            this.axios
              .get(url, {
                headers: { Authorization: auth.code },
                onDownloadProgress: (progressEvent) => {
                  // console.log("progressEvent", progressEvent);
                  let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  // console.log(this.percentCompleted);

                  if (this.newlist[this.dataIndex]["value"] === 95) {
                    this.newlist[this.dataIndex]["value"] = 95;
                  } else {
                    this.newlist[this.dataIndex]["value"] = progresspercent;
                  }
                  // console.log("percenfile", this.newlist[this.dataIndex]["value"]);
                  this.newpercen = this.newlist[this.dataIndex]["value"];
                  this.namefile = this.newlist[this.dataIndex]["name"];
                },
                withCredentials: false,
                responseType: "arraybuffer",
              })
              .then((response) => {
                // ตรวจสอบว่า  API ทำงานถูกต้อง
                // if (response.statusText === "OK") {
                if (response.status === 200) {
                  try {
                    let type = currentfile.file_name.split(".");
                    type = type[type.length - 1].toLowerCase();
                    let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                    if (res_error.status === "ER" && type !== "json") {
                      this.opensnackbar = false;
                      Toast.fire({
                        icon: "error",
                        title: res_error.errorMessage,
                      });
                    } else {
                      this.opensnackbar = true;
                      this.newlist[this.dataIndex]["value"] = 100;
                      this.btsdownload = false;

                      let headers = response.headers;
                      let blob = new Blob([response.data], { type: headers["content-type"] });
                      let link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = currentfile.file_name;
                      link.click();
                      setTimeout(() => {
                        this.opensnackbar = false;
                      }, 2500);
                    }
                  } catch (error) {
                    console.log("error", error);
                    this.opensnackbar = true;
                    this.newlist[this.dataIndex]["value"] = 100;
                    this.btsdownload = false;
                    let headers = response.headers;
                    let blob = new Blob([response.data], { type: headers["content-type"] });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = currentfile.file_name;
                    link.click();
                    setTimeout(() => {
                      this.opensnackbar = false;
                    }, 2500);
                  }
                  // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                  // if (
                  //   // parseInt(response.headers["content-length"]) < 700 &&
                  //   response.headers["content-type"] === "application/json"
                  // ) {
                  //   // Backend ไม่ส่งไฟล์มา
                  //   console.log("response < 1000", response);
                  //   this.opensnackbar = false;
                  //   let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  //   console.log("responseerror", res_error);
                  //   Toast.fire({
                  //     icon: "error",
                  //     title: res_error.errorMessage,
                  //   });
                  //   // Backend ส่งไฟล์มา
                  // } else {
                  //   // console.log("response โหลดได้", response);
                  //   // console.log(parseInt(response.headers["content-length"]));
                  //   this.opensnackbar = true;
                  //   this.newlist[this.dataIndex]["value"] = 100;
                  //   this.btsdownload = false;

                  //   let headers = response.headers;
                  //   let blob = new Blob([response.data], { type: headers["content-type"] });
                  //   let link = document.createElement("a");
                  //   link.href = window.URL.createObjectURL(blob);
                  //   link.download = currentfile.file_name;
                  //   link.click();
                  //   // const blob = new Blob([response.data]);
                  //   // const content = response.headers["content-type"];
                  //   // saveAs(blob, currentfile.file_name);
                  //   setTimeout(() => {
                  //     this.opensnackbar = false;
                  //   }, 2500);
                  // }
                } else {
                  this.opensnackbar = false;
                  Toast.fire({
                    icon: "error",
                    title: this.$t("toast.cannotconnectonebox"),
                  });
                }
                // this.opensnackbar = false;
                this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
                this.newlist = []; //เคลียร์ array เป็น key แล้ว
                this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
                // console.log("ดาวน์โหลดเสร็จfile",this.newlist);
                // console.log("ดาวน์โหลดเสร็จpercenfile",this.newpercen);
              })
              .catch((error) => {
                this.btsdownload = false;
                this.opensnackbar = false;
                Toast.fire({
                  icon: "error",
                  title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
                });
                console.log("err", error);
              });
          }else{
            console.log("เข้า status_encrypt = N");
            this.axios.get(url)
            .then(response => {
              console.log("=",response);
              // แก้เพราะดาวน์โหลดละ error
              if(response.data.url === undefined){
                window.location.href = response.config.url;
              }else{
                window.location.href = response.data.url;
              }
            }).catch(console.error)

          }
            // } else {
            //   this.btsdownload = true;
            //   this.opensnackbardownloadfile = true;
            //   let timeout = checkfilesize * 4000;
            //   window.location.assign(url);
            //   setTimeout(() => {
            //     this.opensnackbardownloadfile = false;
            //   }, timeout);
            // }
          }
        }
      }
    },
    async downloadfile_inbox(currentfile) {
      console.log(currentfile);
      // let checkfilesize = this.fn_calculate_size(currentfile.file_size)
      this.percentCompleted = 0;
      this.percent = 0;
      if (currentfile.file_type === "folder") {
        Toast.fire({
          icon: "warning",
          title: "Coming Soon !!",
        });
        //   let url =
        //     process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        //     "/api/v2/download_folder?account_id=" +
        //     this.dataAccountId +
        //     "&folder_id=" +
        //     currentfile.file_id;
        //   this.snackbardowloadonefile = true;
        // this.axios
        //       .get(url,{
        //         onDownloadProgress: (progressEvent) => {
        //           this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //           console.log(this.percentCompleted);
        //           this.namedowload = currentfile.file_name;
        //           if(this.percentCompleted = 95){
        //             this.percentCompleted = 95;
        //           }

        //         },
        //         withCredentials: false,
        //         responseType: "arraybuffer"
        //       })
        //       .then(response => {
        //         if(response.statusText === "OK"){
        //           this.percentCompleted = 100;
        //         }
        //         // console.log("fff",response);
        //         // this.namedowload = currentfile.file_name;
        //         const blob = new Blob([response.data]);
        //         const content = response.headers['content-type'];
        //         saveAs(blob,currentfile.file_name+ ".zip")

        //       })
        //       .catch(error => {
        //         Toast.fire({
        //           icon: "error",
        //           title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
        //         });
        //         console.log(error);
        //       });
      } else {
        console.log("เข้่า 3");
        this.btsdownload = true;
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id,
        };
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file_inbox?user_id=" +
          // "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id;
        // this.snackbardowloadonefile = true;
        // if(checkfilesize < 1.00){
        this.opensnackbarmultidownload = true;
        this.namedowload = currentfile.filename;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .get(url, {
            headers: { Authorization: auth.code },
            onDownloadProgress: (progressEvent) => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // console.log(progresspercent);
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer",
          })
          .then(async (response) => {
            // if (response.statusText === "OK") {
            if (response.status === 200) {
              if (
                parseInt(response.headers["content-lenght"]) < 700 &&
                (response.headers["content-type"]) ===
                  "application/json"
              ) {
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                let filename = currentfile.filename;
                if (this.feature_renamedocument) {
                  filename = await this.checkRenameDocument(currentfile);
                }

                this.percentCompleted = 100;
                this.btsdownload = false;

                let headers = response.headers;
                let blob = new Blob([response.data], {
                  type: headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                // saveAs(blob, currentfile.filename);

                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                }, 2500);
              }
            } else {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.filename + " นี้ได้",
            });
            console.log(error);
          });
        // }else{
        //   window.location.assign(url)
        //   this.btsdownload = false;
        //   this.opensnackbarmultidownload = false;
        // }
      }
    },
    async checkRenameDocument(file) {
      let auth = await gbfGenerate.generateToken();
      let filename = file.filename;
      await this.axios
        .get(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_rename_document",
          {
            headers: { Authorization: auth.code },
            params: { file_id: file.file_id }
          }
        )
        .then((res) => {
          if (res.data.status === "OK") {
            filename = res.data.filename;
          } else {
            Toast.fire({
              icon: "error",
              title: res.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("myinboxPage.msg.norenamedocument"),
          });
        });
      return filename;
    },
    seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "mov" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link-outline", "pink"];
      } else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },
    cleardata() {
      this.$v.$reset();
      this.foldername = "";
      this.createprogress = false;
    },
    closedialog() {
      if (this.feature_onlyoffice === true || (this.fileSharedFeature !== undefined && this.fileSharedFeature !== null)){   
        if(['04', '05', '06'].includes(this.filedata.file_permission_2)){
          if (this.filedata_1.file_type !== "doc" && this.filedata_1.file_type !== "ppt" && this.filedata_1.file_type !== "xls") {
            this.fn_check_countopen();
          }
        }
      } 
        
      this.rotatedegree = 0
      this.scalefarenhi = 1
      console.log("close");
      this.$emit("closepreviewfile");
      // this.rawbase64 = "";
      // this.loading = true;
      // this.filearraybuffer = "";
      // this.compatibletype = "";
      // this.compatible = false;
      // this.pageCount = 0;
      // this.typeviewer = "";
      // this.displayloading = "";
      // this.displayiframe = "display: none";
      // this.displaynotcompatible = "display: none";
    },
    fn_check_countopen(){
      this.fn_create_destroy_event(false);
      let key_id = VueCookies.get("user_key");
      var payload = {
          account_id: this.dataAccountId,
          file_id: this.filedata.file_id,
          user_id_key: this.dataUsername + '_' + key_id,
        };
        this.axios
          .post(this.fileSharedFeature !== undefined && this.fileSharedFeature !== null ? this.onlyofficeDomain_citizen + "/document_editor/api/closed_preview_onlyoffice" : this.onlyofficeDomain + "/document_editor/api/closed_preview_onlyoffice", payload )
          .then((response) => {
            if (response.data.status === "OK") {
              this.$emit('reload')
              // console.log("78980",response);

            }
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          });
    },
    async fn_insert_logpreview(){
      let key_id = VueCookies.get("user_key");
        var payload = {
          account_id: this.dataAccountId,
          file_id: this.filedata.file_id,
          user_id_key: this.dataUsername + '_' + key_id,
        };
        await this.axios
          .post(this.onlyofficeDomain + "/document_editor/api/insert_log_preview_onlyoffice", payload )
          .then((response) => {
            if (response.data.status === "OK") {
              console.log("response",response);
               VueCookies.set("file",response.data.data.file_id);
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: error,
            });
          });
    },
    //ของ citizen shared file
    async fn_insert_logpreview_citizen_sharedfile (){
      let key_id = VueCookies.get("user_key");
        var payload = {
          account_id: this.dataAccountId,
          file_id: this.filedata.file_id,
          user_id_key: this.dataUsername + '_' + key_id,
        };
        await this.axios
          .post(this.onlyofficeDomain_citizen + "/document_editor/api/insert_log_preview_onlyoffice", payload )
          .then((response) => {
            if (response.data.status === "OK") {
               VueCookies.set("file",response.data.data.file_id);
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: error,
            });
          });
    },
    // ดักการรีโหลด
    fn_create_destroy_event(status) {
      if (status === true) {
        window.addEventListener('beforeunload', this.fn_check_countopen);
        window.addEventListener('popstate', this.fn_event_back_foward);
      } else {
        window.removeEventListener('popstate', this.fn_event_back_foward);
        window.removeEventListener('beforeunload', this.fn_check_countopen);
        const elementImg = document.getElementsByClassName('testImage')[0]
        try{
          if(this.screenSize >= 500){
            elementImg.removeEventListener("mousedown", this.fn_mousedown)
            elementImg.removeEventListener("mousemove", this.fn_newmousemoving)
          } else {
            elementImg.removeEventListener("touchstart", this.fn_mousedown)
            elementImg.removeEventListener("touchmove", this.fn_newmousemoving)
          }
        } catch (err) {
          console.log("Error Remove Event")
        }
        
      }
    },
    async fn_event_back_foward() {
      await this.fn_check_countopen();
      // let key_id = VueCookies.get("user_key");
      let payload = {
        file_id: this.filedata.file_id,
        user_id: this.dataUsername,
        status: "N"
      }
      this.axios
        .post(this.fileSharedFeature !== undefined && this.fileSharedFeature !== null ? this.onlyofficeDomain_citizen + "document_editor/api/status_editing_file" : this.onlyofficeDomain + "document_editor/api/status_editing_file", payload )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response",response);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
    },
    fn_onResize(e) {
      if (!(window.innerWidth >= this.playerOptions.width + 30 && window.innerWidth <= this.playerOptions.width + 100)) {
        this.playerOptions.height = window.innerHeight - 150;
        this.playerOptions.width = window.innerWidth - 70;
      } 
    },
    // ฟังค์ชั่นนเก่าาา (ไม่ได้ใช้งาน) รอคลีนโค้ด
    createEventBoundaries (scale_, overflowLeft, overflowTop) {
        const elementImg = document.getElementsByClassName('testImage')[0]
        const canvasId = document.getElementById("imageratio")
        const containerRect = canvasId.getBoundingClientRect();
        const ImageReact = elementImg.getBoundingClientRect();
        const isOverTop = (ImageReact.top < containerRect.top)
        const isOverleft = (ImageReact.left < containerRect.left)
        const isOverTopAndLeft = (ImageReact.top < containerRect.top && ImageReact.left < containerRect.left)
        if(this.rememberScale !== 0) {
              if(scale_ < this.rememberScale) {
                elementImg.style.transform = `translate(0px, 0px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.rememberScale = 0
                this.translate_posX = 0
                this.translate_posY = 0
                this.fit_onleft_container = false
                this.fit_onright_container = false
                this.fit_ontop_container = false
                try{
                  if(this.screenSize >= 500) {
                    console.log("Remove Event Draging Window")
                    elementImg.removeEventListener("mousedown", this.fn_mousedown)
                  } else {
                    console.log("Remove Event Draging")
                    elementImg.removeEventListener("touchstart", this.fn_touchdownmobile)
                  }
                } catch (err) {
                  console.log("err remvoe ");
                }
              } else {
                if(overflowLeft > 0 && overflowTop <= 0){
                  console.log("หรือเข้านี่")
                  if(this.fit_onleft_container || this.fit_onright_container) {
                    this.translate_posX = this.fit_onright_container ? -this.overflowLeft : this.overflowLeft
                    elementImg.style.transform = `translate(${this.translate_posX}px, ${0}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                  }
                }
                if(overflowTop > 0 && overflowLeft <= 0) {
                  if(this.fit_ontop_container || this.fit_onbottom_container) {
                    this.translate_posY = this.fit_ontop_container ? this.overflowTop : -this.overflowTop
                    elementImg.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                  } else {
                    console.log("SCALLLLLLLLE ")
                    elementImg.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                  }
                }
                if(this.screenSize >= 500) {
                  elementImg.removeEventListener("mousedown", this.fn_mousedown)
                  console.log("Stilll thereeeee 1111")
                  elementImg.addEventListener('mousedown', this.fn_mousedown);
                  elementImg.addEventListener('mousemove', (event) => this.fn_mousemoving(event, overflowTop, this.overflowLeft, elementImg, scale_, isOverTop, isOverleft, isOverTopAndLeft));
                  elementImg.addEventListener('mouseup', (event) => {
                  this.isDragging_image = false
                  elementImg.removeEventListener("moussemove", this.fn_mousemoving, false)
                  //document.getElementById("rememberScale").innerHTML = this.rememberScale
                  });
                } else {
                  elementImg.removeEventListener("touchstart", this.fn_touchdownmobile)
                  
                }
                
              }
        } else {
          if(overflowLeft > 0 || overflowTop > 0){
              this.rememberScale = scale_
              if (this.screenSize >= 500) {
                elementImg.addEventListener('mousedown', this.fn_mousedown);
                elementImg.addEventListener('mousemove', (event) => this.fn_mousemoving(event, overflowTop, this.overflowLeft, elementImg, scale_, isOverTop, isOverleft, isOverTopAndLeft));
                elementImg.addEventListener('mouseup', (event) => {
                this.isDragging_image = false
                elementImg.removeEventListener("mousemove", () => {this.fn_mousemoving})
                });
              } else {
                console.log("Stilll thereeeee 222")
                elementImg.addEventListener('touchstart', this.fn_touchdownmobile)
                elementImg.addEventListener('touchmove', (event) => this.fn_touchmoving(event, overflowTop, this.overflowLeft, elementImg, scale_, isOverTop, isOverleft, isOverTopAndLeft));
                elementImg.addEventListener('touchend', (event) => {
                  this.isDragging_image = false
                  elementImg.removeEventListener("touchmove", () => {this.fn_mousemoving})
                });
              }
              
          } else {
            elementImg.style.transform = `translate(0px, 0px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
            this.translate_posX = 0 
            this.translate_posY = 0
            this.rememberScale = 0
            try{
              if(this.screenSize >= 500) {
                elementImg.removeEventListener("mousedown", this.fn_mousedown)
              } else {
                console.log("Closeeeeed 222 ", overflowLeft)
                elementImg.removeEventListener("touchstart", this.fn_touchdownmobile)
              }
            } catch (err) {
              console.log("err remvoe ");
            }
          }
        }
    },
    isiPadAirOrPro() {
    var userAgent = navigator.userAgent;
    return /iPad/i.test(userAgent) && !/CriOS/i.test(userAgent) && (/iPad4,1|iPad4,2|iPad4,3|iPad5,3|iPad5,4|iPad6,11|iPad6,12|iPad7,5|iPad7,6|iPad8,1|iPad8,2|iPad8,3|iPad8,4|iPad8,5|iPad8,6|iPad8,7|iPad8,8/.test(userAgent) || /iPad11,3|iPad11,4|iPad13,1|iPad13,2|iPad13,4|iPad13,5|iPad13,6|iPad13,7/.test(userAgent));
    },
    // ฟังค์ชั่นใหม่ (ใช้งาน)
    NewCreateEventBoundaries (elementImg) {
      if(this.isOverLeft || this.isOverTop || this.isOverTopAndLeft) {
        elementImg.style.cursor = "grab"
      } else {
        elementImg.style.cursor = "auto"
      }
      if(this.rememberScale !== 0) {
        if(this.scalefarenhi < this.rememberScale) {
            elementImg.style.transform = `translate(0px, 0px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
            elementImg.style.cursor = "auto"
            this.rememberScale = 0
            this.translate_posX = 0
            this.translate_posY = 0
            this.fit_onleft_container = false
            this.fit_onright_container = false
            this.fit_ontop_container = false
            try{
              if(this.screenSize >= 500) {
                elementImg.removeEventListener("mousedown", this.fn_mousedown)
                elementImg.removeEventListener("mousemove", this.fn_newmousemoving)
              } else {
                elementImg.removeEventListener("touchstart", this.fn_mousedown)
                elementImg.removeEventListener("touchmove", this.fn_newmousemoving)
              }
            } catch (err) {
              console.log("err remvoe ");
            }
        } else {
            // ฟิตซ้ายขวา
            if(this.overflowLeft > 0 && this.overflowTop <= 0){
                if(this.fit_onleft_container || this.fit_onright_container) {
                  this.translate_posX = this.fit_onright_container ? -this.overflowLeft : this.overflowLeft
                  elementImg.style.transform = `translate(${this.translate_posX}px, ${0}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                }
            }

            // ฟิตบนล่าง
            if(this.overflowTop > 0 && this.overflowLeft <= 0) {
                  if(this.fit_ontop_container || this.fit_onbottom_container) {
                    this.translate_posY = this.fit_ontop_container ? this.overflowTop : -this.overflowTop
                    elementImg.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                  } else {
                    elementImg.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                  }
            }

            if(this.overflowTop > 0 && this.overflowLeft > 0) {
                  if(this.fit_ontop_container && this.fit_onleft_container) {
                    this.translate_posY = this.overflowTop
                    this.translate_posX = this.overflowLeft
                    
                  } else if (this.fit_ontop_container && this.fit_onright_container) {
                    this.translate_posY = this.overflowTop
                    this.translate_posX = -this.overflowLeft
                  } else if (this.fit_onbottom_container && this.fit_onleft_container) {
                    this.translate_posY = -this.overflowTop
                    this.translate_posX = this.overflowLeft
                  } else if (this.fit_onbottom_container && this.fit_onright_container) {
                    this.translate_posY = -this.overflowTop
                    this.translate_posX = -this.overflowLeft
                  } else if (this.fit_ontop_container || this.fit_onbottom_container) {
                    this.translate_posY = this.fit_ontop_container ? this.overflowTop : -this.overflowTop
                  } else if (this.fit_onright_container || this.fit_onleft_container) {
                    this.translate_posX = this.fit_onright_container ? this.overflowLeft : -this.overflowLeft
                  }
                  elementImg.style.transform = `translate(${this.translate_posX}px, ${this.translate_posY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
            }

            // เปิด Event mousemove and touchmove
            if(this.screenSize >= 500){
              elementImg.addEventListener('mousedown', this.fn_mousedown);
              elementImg.addEventListener('mousemove', this.fn_newmousemoving )
              elementImg.addEventListener('mouseup', (event) => {
                this.isDragging_image = false
              });
            } else {
              this.imageItems.addEventListener('touchstart', this.fn_mousedown);
              this.imageItems.addEventListener('touchmove', this.fn_newmousemoving)
              this.imageItems.addEventListener('touchend', (event) => {
                  this.isDragging_image = false
                });
          }

        }
      } else {
        if (this.overflowLeft > 0 || this.overflowTop > 0) {
          this.rememberScale = this.scalefarenhi
          // เปิด Event mousemove and touchmove
          if(this.screenSize >= 500){
            this.imageItems.addEventListener('mousedown', this.fn_mousedown);
            this.imageItems.addEventListener('mousemove', this.fn_newmousemoving)
            this.imageItems.addEventListener('mouseup', (event) => {
                this.isDragging_image = false
                this.imageItems.style.cursor = "grab"
              });
            this.imageItems.addEventListener("mouseleave", () => {
              this.isDragging_image = false
              this.imageItems.style.cursor = "grab"
            })
          } else {
            this.imageItems.addEventListener('touchstart', this.fn_mousedown);
            this.imageItems.addEventListener('touchmove', this.fn_newmousemoving)
            this.imageItems.addEventListener('touchend', (event) => {
                this.isDragging_image = false
              });
          }
        } else {
            elementImg.style.transform = `translate(0px, 0px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
            elementImg.style.cursor = "auto"
            this.translate_posX = 0 
            this.translate_posY = 0
            this.rememberScale = 0
            try{
              if(this.screenSize >= 500) {
                elementImg.removeEventListener("mousedown", this.fn_mousedown)
              } else {
                elementImg.removeEventListener("touchstart", this.fn_mousedown)
                elementImg.removeEventListener("touchmove", this.fn_newmousemoving)
              }
            } catch (err) {
              console.log("err remvoe ");
            }
        }
      }
    },
    
    // ไม่ใช้งาน (**อยู่ใน createEventBoundaries )
    fn_touchdownmobile (event) {
      this.isDragging_image = true
      this.initialMouseX = event.targetTouches["0"].clientX - this.translate_posX 
      this.initialMouseY = event.targetTouches["0"].clientY - this.translate_posY;
    },
    // ไม่ใช้งาน (**อยู่ใน createEventBoundaries )
    fn_mousemoving (event, overflowTop, overflowLeft, elementImg, scale_, isOverTop, isOverleft, isOverTopAndLeft) {
      if(this.isDragging_image){
        if(isOverleft && !isOverTopAndLeft){
          let deltaX = event.clientX - this.initialMouseX
          this.translate_posX = deltaX
          if(this.translate_posX < overflowLeft){
            if(Math.abs(this.translate_posX) >= overflowLeft){
              elementImg.style.transform = `translate(${-overflowLeft}px, ${0}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
              this.translate_posX = -overflowLeft
              //document.getElementById("deltaX").innerHTML = `DeltaX ${-overflowLeft}`
              this.fit_onright_container = true
            } else {
              elementImg.style.transform = `translate(${this.translate_posX}px, ${0}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
              //document.getElementById("deltaX").innerHTML = `DeltaX ${this.translate_posX}`
              this.fit_onleft_container = false
              this.fit_onright_container = false
            }
          } else {
                elementImg.style.transform = `translate(${overflowLeft}px, ${0}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = overflowLeft
                //document.getElementById("deltaX").innerHTML = `DeltaX ${overflowLeft}`
                this.fit_onleft_container = true
          }
        }
      
        if(isOverTop && !isOverTopAndLeft) {
          console.log("Overflow only top")
            let deltaY = event.clientY - this.initialMouseY
            this.translate_posY = deltaY
            //document.getElementById("deltaY").innerHTML = `DeltaX ${deltaY}`
            if(this.translate_posY < overflowTop){
              if(Math.abs(this.translate_posY) >= overflowTop){
                elementImg.style.transform = `translate(${0}px, ${-overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -overflowTop
                this.fit_onbottom_container = true
              } else {
                elementImg.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.fit_ontop_container = false
                this.fit_onbottom_container = false
              }
            } else {
                elementImg.style.transform = `translate(${0}px, ${overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = overflowTop
                this.fit_ontop_container = true
            }
        }

        if(isOverTopAndLeft) {
            let deltaY = event.clientY - this.initialMouseY
            let deltaX = event.clientX - this.initialMouseX
            this.translate_posY = deltaY
            this.translate_posX = deltaX
            if(this.translate_posY >= overflowTop && this.translate_posX >= overflowLeft) {
              elementImg.style.transform = `translate(${overflowLeft}px, ${overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
              this.translate_posX = overflowLeft
              this.translate_posY = overflowTop
            } 
            // else
            // บนซ้ายยย / ล่างซ้าย 
            if (Math.abs(this.translate_posY) >= overflowTop && Math.abs(this.translate_posX) >= overflowLeft) {
              if(this.translate_posY > 0) {
                elementImg.style.transform = `translate(${this.translate_posX > 0 ? overflowLeft : -overflowLeft}px, ${overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = overflowTop
              } else {
                elementImg.style.transform = `translate(${this.translate_posX > 0 ? overflowLeft : -overflowLeft}px, ${-overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -overflowTop
              }
              this.translate_posX = this.translate_posX > 0 ? overflowLeft : -overflowLeft
            }
            // บน / ล่าง
            else if (Math.abs(this.translate_posY) >= overflowTop) {
              if(this.translate_posY > 0) {
                elementImg.style.transform = `translate(${deltaX}px, ${overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = overflowTop
              } else {
                elementImg.style.transform = `translate(${deltaX}px, ${-overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -overflowTop
              }
            } 
            // ซ้าย / ขวา
            else if (Math.abs(this.translate_posX) >= overflowLeft) {
              if(this.translate_posX > 0) {
                elementImg.style.transform = `translate(${overflowLeft}px, ${deltaY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = overflowLeft
              } else {
                elementImg.style.transform = `translate(${-overflowLeft}px, ${deltaY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = -overflowLeft
              }
            } 
            else {
              elementImg.style.transform = `translate(${deltaX}px, ${deltaY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
            }
            
            
        }
      }
    },

    // ไม่ใช้งาน (**อยู่ใน createEventBoundaries )
    fn_touchmoving (event, overflowTop, overflowLeft, elementImg, scale_, isOverTop, isOverleft, isOverTopAndLeft) {
      let deltaX = event.targetTouches["0"].clientX - this.initialMouseX
      let deltaY = event.targetTouches["0"].clientY - this.initialMouseY
      console.log(`deltaaaX  ${deltaX} delTaYY ${deltaY}`)
      elementImg.style.transform = `translate(${deltaX}px, ${deltaY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
      if(this.isDragging_image){
        if(isOverleft && !isOverTopAndLeft){
          let deltaX = event.targetTouches["0"].clientX - this.initialMouseX
          this.translate_posX = deltaX
          if(this.translate_posX < overflowLeft){
            if(Math.abs(this.translate_posX) >= overflowLeft){
              elementImg.style.transform = `translate(${-overflowLeft}px, ${0}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
              this.translate_posX = -overflowLeft
              //document.getElementById("deltaX").innerHTML = `DeltaX ${-overflowLeft}`
              this.fit_onright_container = true
            } else {
              elementImg.style.transform = `translate(${this.translate_posX}px, ${0}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
              //document.getElementById("deltaX").innerHTML = `DeltaX ${this.translate_posX}`
              this.fit_onleft_container = false
              this.fit_onright_container = false
            }
          } else {
                elementImg.style.transform = `translate(${overflowLeft}px, ${0}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = overflowLeft
                //document.getElementById("deltaX").innerHTML = `DeltaX ${overflowLeft}`
                this.fit_onleft_container = true
          }
        }
      
        if(isOverTop && !isOverTopAndLeft) {
          console.log("Overflow only top")
            let deltaY = event.targetTouches["0"].clientY - this.initialMouseY
            this.translate_posY = deltaY
            //document.getElementById("deltaY").innerHTML = `DeltaX ${deltaY}`
            if(this.translate_posY < overflowTop){
              if(Math.abs(this.translate_posY) >= overflowTop){
                elementImg.style.transform = `translate(${0}px, ${-overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -overflowTop
                this.fit_onbottom_container = true
              } else {
                elementImg.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.fit_ontop_container = false
                this.fit_onbottom_container = false
              }
            } else {
                elementImg.style.transform = `translate(${0}px, ${overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = overflowTop
                this.fit_ontop_container = true
            }
        }

        if(isOverTopAndLeft) {
            let deltaY = event.targetTouches["0"].clientY - this.initialMouseY
            let deltaX = event.targetTouches["0"].clientX - this.initialMouseX
            this.translate_posY = deltaY
            this.translate_posX = deltaX
            if(this.translate_posY >= overflowTop && this.translate_posX >= overflowLeft) {
              elementImg.style.transform = `translate(${overflowLeft}px, ${overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
              this.translate_posX = overflowLeft
              this.translate_posY = overflowTop
            } 
            // else
            // บนซ้ายยย / ล่างซ้าย 
            if (Math.abs(this.translate_posY) >= overflowTop && Math.abs(this.translate_posX) >= overflowLeft) {
              if(this.translate_posY > 0) {
                elementImg.style.transform = `translate(${this.translate_posX > 0 ? overflowLeft : -overflowLeft}px, ${overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = overflowTop
              } else {
                elementImg.style.transform = `translate(${this.translate_posX > 0 ? overflowLeft : -overflowLeft}px, ${-overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -overflowTop
              }
              this.translate_posX = this.translate_posX > 0 ? overflowLeft : -overflowLeft
            }
            // บน / ล่าง
            else if (Math.abs(this.translate_posY) >= overflowTop) {
              if(this.translate_posY > 0) {
                elementImg.style.transform = `translate(${deltaX}px, ${overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = overflowTop
              } else {
                elementImg.style.transform = `translate(${deltaX}px, ${-overflowTop}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -overflowTop
              }
            } 
            // ซ้าย / ขวา
            else if (Math.abs(this.translate_posX) >= overflowLeft) {
              if(this.translate_posX > 0) {
                elementImg.style.transform = `translate(${overflowLeft}px, ${deltaY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = overflowLeft
              } else {
                elementImg.style.transform = `translate(${-overflowLeft}px, ${deltaY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = -overflowLeft
              }
            } 
            else {
              elementImg.style.transform = `translate(${deltaX}px, ${deltaY}px) scale(${scale_}) rotate(${this.rotatedegree}deg)`;
            }
            
            
        }
      }
    },


    // ใช้งาน (**อยู่ใน NeCreateEventBoundaries )
    fn_mousedown (event) {
      this.imageItems.style.cursor = "grabbing"
      this.isDragging_image = true
      this.initialMouseX = this.screenSize >= 500 ? event.clientX - this.translate_posX : event.targetTouches["0"].clientX - this.translate_posX
      this.initialMouseY = this.screenSize >= 500 ? event.clientY - this.translate_posY : event.targetTouches["0"].clientY - this.translate_posY
    },

    // ใช้งาน (**อยู่ใน NeCreateEventBoundaries )
    fn_newmousemoving (event) {
      if(this.isDragging_image){
        if(this.isOverLeft && !this.isOverTopAndLeft){
          let deltaX = this.screenSize >= 500 ? event.clientX - this.initialMouseX : event.targetTouches["0"].clientX - this.initialMouseX
          this.translate_posX = deltaX
          if(this.translate_posX < this.overflowLeft){
            if(Math.abs(this.translate_posX) >= this.overflowLeft){
              this.imageItems.style.transform = `translate(${-this.overflowLeft}px, ${0}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
              this.translate_posX = -this.overflowLeft
              //document.getElementById("deltaX").innerHTML = `DeltaX ${-overflowLeft}`
              this.fit_onright_container = true
            } else {
              this.imageItems.style.transform = `translate(${this.translate_posX}px, ${0}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
              //document.getElementById("deltaX").innerHTML = `DeltaX ${this.translate_posX}`
              this.fit_onleft_container = false
              this.fit_onright_container = false
            }
          } else {
            this.imageItems.style.transform = `translate(${this.overflowLeft}px, ${0}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
            this.translate_posX = this.overflowLeft
            //document.getElementById("deltaX").innerHTML = `DeltaX ${overflowLeft}`
            this.fit_onleft_container = true
          }
        }
      
        if(this.isOverTop && !this.isOverTopAndLeft) {
            let deltaY = this.screenSize >= 500 ? event.clientY - this.initialMouseY : event.targetTouches["0"].clientY - this.initialMouseY
            this.translate_posY = deltaY
            if(this.translate_posY < this.overflowTop){
              if(Math.abs(this.translate_posY) >= this.overflowTop){
                this.imageItems.style.transform = `translate(${0}px, ${-this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -this.overflowTop
                this.fit_onbottom_container = true
              } else {
                this.imageItems.style.transform = `translate(${0}px, ${this.translate_posY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.fit_ontop_container = false
                this.fit_onbottom_container = false
              }
            } else {
                this.imageItems.style.transform = `translate(${0}px, ${this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = this.overflowTop
                this.fit_ontop_container = true
            }
        }

        if(this.isOverTopAndLeft) {
            let deltaY = this.screenSize >= 500 ? event.clientY - this.initialMouseY : event.targetTouches["0"].clientY - this.initialMouseY
            let deltaX = this.screenSize >= 500 ? event.clientX - this.initialMouseX : event.targetTouches["0"].clientX - this.initialMouseX
            this.translate_posY = deltaY
            this.translate_posX = deltaX
            if(this.translate_posY >= this.overflowTop && this.translate_posX >= this.overflowLeft) {
              this.imageItems.style.transform = `translate(${this.overflowLeft}px, ${this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
              this.translate_posX = this.overflowLeft
              this.translate_posY = this.overflowTop
            } 
            // else
            // บนซ้ายยย / ล่างซ้าย 
            if (Math.abs(this.translate_posY) >= this.overflowTop && Math.abs(this.translate_posX) >= this.overflowLeft) {
              if(this.translate_posY > 0) {
                this.imageItems.style.transform = `translate(${this.translate_posX > 0 ? this.overflowLeft : -this.overflowLeft}px, ${this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = this.overflowTop
              } else {
                this.imageItems.style.transform = `translate(${this.translate_posX > 0 ? this.overflowLeft : -this.overflowLeft}px, ${-this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -this.overflowTop
              }
              this.translate_posX = this.translate_posX > 0 ? this.overflowLeft : -this.overflowLeft
              if(this.translate_posX > 0 && this.translate_posY > 0){
                this.fit_ontop_container = true
                this.fit_onleft_container = true
                this.fit_onbottom_container = false
                this.fit_onright_container = false
                console.log("บนซ้ายยยยยยยยยยยยยยยยยยยย", this.translate_posY)
              } 
              else if (this.translate_posX > 0 && this.translate_posY < 0) {
                this.fit_onbottom_container = true
                this.fit_onleft_container = true
                this.fit_onright_container = false
                this.fit_ontop_container = false
                console.log("ล่างซ้ายยยยยยยยยยยยยยยยยยยย", this.translate_posY)
              } 
              else if (this.translate_posX < 0 && this.translate_posY > 0) {
                this.fit_onright_container = true
                this.fit_ontop_container = true
                this.fit_onbottom_container = false
                this.fit_onleft_container = false
                console.log("บนขวาาาาาาาาาาาาาาาาาาาาาาาาา", this.translate_posY)
              } 
              else if (this.translate_posX < 0 && this.translate_posY < 0) {
                this.fit_onright_container = true
                this.fit_onbottom_container = true
                this.fit_onleft_container = false
                this.fit_ontop_container = false
              }
            }
            // บน / ล่าง
            else if (Math.abs(this.translate_posY) >= this.overflowTop) {
              if(this.translate_posY > 0) {
                this.imageItems.style.transform = `translate(${deltaX}px, ${this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = this.overflowTop
                this.fit_onright_container = false
                this.fit_onbottom_container = false
                this.fit_onleft_container = false
                this.fit_ontop_container = true
                console.log("บนนนนนนนนนนนนนนน")
              } else {
                this.imageItems.style.transform = `translate(${deltaX}px, ${-this.overflowTop}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posY = -this.overflowTop
                this.fit_onright_container = false
                this.fit_onbottom_container = true
                this.fit_onleft_container = false
                this.fit_ontop_container = false
                console.log("ล่างงงงงงงงงงงงงงงงง")
              }
            } 
            // ซ้าย / ขวา
            else if (Math.abs(this.translate_posX) >= this.overflowLeft) {
              if(this.translate_posX > 0) {
                this.imageItems.style.transform = `translate(${this.overflowLeft}px, ${deltaY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = this.overflowLeft
              } else {
                this.imageItems.style.transform = `translate(${-this.overflowLeft}px, ${deltaY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
                this.translate_posX = -this.overflowLeft
              }
            } 
            else {
              this.imageItems.style.transform = `translate(${deltaX}px, ${deltaY}px) scale(${this.scalefarenhi}) rotate(${this.rotatedegree}deg)`;
              this.fit_onbottom_container = false
              this.fit_onleft_container = false
              this.fit_onright_container = false
              this.fit_ontop_container = false
            }
        }
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.fn_onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.fn_onResize);
  }
};
</script>

<style scoped>
.watermarked-test {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    overflow: hidden;
    background-color: transparent;
}
img {
  -webkit-user-drag: none;
  user-drag: none;
}
/* แบบเก่า */
/* <style scoped type="text/css"> */
/* body { visibility: hidden; display: none } */
/* ปิดปุ่มscroll */
.v-dialog--fullscreen {
    overflow-y: clip;
}
.position_overlay {
    margin-top: 640px;
}
/* watermarked */
.watermarked:after {
  color: rgba(0, 0, 0, 0.15);
  content: attr(data-watermark);
  transform: rotate(-45deg);
  font-size: 15px;
  font-weight: bold;
  font-kerning: normal;
  letter-spacing: 1px;
  word-spacing: 1em;
  position: absolute;
  top: 30%;
  left: 0%;
  width: max-content;
  height: max-content;
  filter: drop-shadow(2em 6em #000) drop-shadow(4em 12em #000)
    drop-shadow(8em 24em #000) drop-shadow(-15em -48em #000);
}
.watermarked {
  position: relative;
  overflow: hidden;
}
#body {
  max-width: max-content;
}

/* img, .watermarked:after {
  grid-row: 1;
  grid-column: 1;
  margin: auto;
} */

#detailpreview {
  padding-top: 50px;
  padding-bottom: 25px;
  height: auto;
  overflow-y: auto;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 70;
  left: 0;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}

#dialogpreview {
  background: rgba(0, 0, 0, 0.85);
}

#zoompdf {
  padding-bottom: 20px;
  width: 90%;
}

/* .pdf_render {
  overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
    overflow-scrolling: touch;
} */

#thiscontainer {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}

/* .iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  padding-bottom: 0;
  height: 80%;
  
} */

/* .iframe-container iframe {
   border: 0;
   height: 85%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
} */

.iframe-container {
  position: relative;
  padding-top: 47%;
  height: 0;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overscroll-behavior: contain;
}
.iframe-container-mobile {
  position: relative;
  padding-top: 165%;
  height: 0;
}
.iframe-container-mobile iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overscroll-behavior: contain;
}
#iframeratio2 {
  height: 90vh;
  /* max-width: 99vw; */
  width: 100vw;
}
#iframeratio {
  /* แบบเก่า */
  /* height: 90vh;
  max-width: 99vw;
  width: 100vw; */
  /* แบบใหม่ */
  height: 91.5vh;
  max-width: 100vw;
  width: 100vw;
  margin-bottom: 20px;
}
#imageratio {
  height: 91.5vh;
  max-width: 99vw;
  width: 99vw;
  margin-bottom: 20px;
  border: 1px solid gray;
  display: flex; 
  justify-content: center; 
  align-items: center;
}
@media print {
  #body {
    visibility: hidden;
    display: none;
  }
}
@media screen and (max-width: 800px) {
  /* For mobile phones: */
  #iframeratio {
    height: 73vh;
    max-width: 99vw;
    width: 80vw;
  }
  /* .watermarked:after {
  draw the watermark at screen
  color: rgba(0, 0, 0, 0.15);
  content: attr(dataname-watermark) " " attr(dataemail-watermark) ;
  word-spacing: 1em;
  transform: rotate(-45deg);
  font-size: 20px;
  font-weight: bold;
  font-kerning: normal;
  line-height: 5em;
  letter-spacing: 1px;
  filter: 
  drop-shadow(2em 3em  #000) drop-shadow(4em 6em #000) drop-shadow(8em 12em #000) drop-shadow(-15em -24em #000); 
  }
.watermarked {  
  width: max-content;
  border: solid;
  display: grid;
  overflow: hidden;
  height: 100%;
  width: 100%;
}iframe,
.watermarked:after {
  grid-row: 2;
  grid-column: 2;
  margin: auto;
}
img,
.watermarked:after {
  grid-row: 2;
  grid-column: 2;
  margin: auto;
} */
}

@media screen and (max-width: 300px) {
  /* For mobile phones: */
  #iframeratio {
    height: 73vh;
    max-width: 99vw;
    width: 80vw;
  }
  /* #imageratio {
    height: 73vh;
    max-width: 70vw;
    width: 70vw;
  } */
  /* .watermarked:after {
  draw the watermark at screen
  color: rgba(0, 0, 0, 0.15);
  content: attr(dataname-watermark) " " attr(dataemail-watermark)  ;
  word-spacing: 1em;
  transform: rotate(-45deg);
  font-size: 20px;
  font-weight: bold;
  font-kerning: normal;
  line-height: 5em;
  letter-spacing: 1px; 
  filter: 
  drop-shadow(2em 3em  #000) drop-shadow(4em 6em #000) drop-shadow(8em 12em #000) drop-shadow(-15em -24em #000);
  }
.watermarked {
  width: max-content;
  border: solid;
  display: grid;
  overflow: hidden;
  height: 100%;
  width: 100%;
  
}
iframe,
.watermarked:after {
  grid-row: 2;
  grid-column: 2;
  margin: auto;
}
img,
.watermarked:after {
  grid-row: 2;
  grid-column: 2;
  margin: auto;
} */
}

@media screen and (min-width: 2500px) {
  /* For mobile phones: */
  #iframeratio {
    height: 90vh;
    max-width: 99vw;
    width: 80vw;
  }
  /* #imageratio {
    height: 90vh;
    max-width: 80vw;
    width: 80vw;
  } */
  /* .watermarked:after {
  draw the watermark at screen
  color: rgba(0, 0, 0, 0.15);
  content: attr(dataname-watermark) " " attr(dataemail-watermark) " " attr(dataname-watermark) " " attr(dataemail-watermark) " " attr(dataname-watermark) " " attr(dataemail-watermark) " " attr(dataname-watermark);
  word-spacing: 1em;
  transform: rotate(-45deg);
  font-size: 20px;
  font-weight: bold;
  font-kerning: normal;
  line-height: 5em;
  letter-spacing: 1px;
  filter: 
  drop-shadow(2em 3em  #000) drop-shadow(4em 6em #000) drop-shadow(8em 12em #000) drop-shadow(-15em -24em #000);

}
.watermarked {
  
  width: max-content;
  border: solid;
  display: grid;
  overflow: hidden;
}iframe,
.watermarked:after {
  grid-row: 1;
  grid-column: 1;
  margin: auto;
}
img,
.watermarked:after {
  grid-row: 1;
  grid-column: 1;
  margin: auto;
} */
}

@media screen and (width: 1024px) {
  /* For mobile phones: */
  #iframeratio {
    height: 90vh;
    max-width: 99vw;
    width: 80vw;
  }
  /* #imageratio {
    height: 90vh;
    max-width: 80vw;
    width: 80vw;
  } */
  /* .watermarked:after {
  draw the watermark at screen
  color: rgba(0, 0, 0, 0.15);
  content: attr(dataname-watermark) " " attr(dataemail-watermark) " " attr(dataname-watermark) " " attr(dataemail-watermark);
  word-spacing: 1em;
  transform: rotate(-45deg);
  font-size: 15px;
  font-weight: bold;
  font-kerning: normal;
  line-height: 5em;
  letter-spacing: 1px;
  filter: 
  drop-shadow(2em 3em  #000) drop-shadow(4em 6em #000) drop-shadow(8em 12em #000) drop-shadow(-15em -24em #000);
  }
.watermarked {
  
  width: max-content;
  border: solid;
  display: grid;
  overflow: hidden;
  height: 100%;
  width: 100%;
}iframe,
.watermarked:after {
  grid-row: 1;
  grid-column: 1;
  margin: auto;
}
img,
.watermarked:after {
  grid-row: 1;
  grid-column: 1;
  margin: auto;
} */
}

.modal-contentc {
  margin: auto;
  display: block;
  max-width: 85vw !important;
  max-height: 70vh !important;
}
.lean-left {
  transform: rotate(-45deg);
}
.text-water-marked-default  {
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 1em;
  position: absolute;
  /*  */
}
.text-water-marked {
  /* CUSTOM WATERMARK */
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 1em;
  position: absolute;
  top: -70%;
  left: -70%;
  display: block;
  width: 250%;
  height: 250%;
  line-height: 100px;
}
</style>